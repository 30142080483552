<template>
  <div class="main-content" v-bind="$attrs">
    <div class="noselect template-data main-width filmstro-right-border filmstro-bottom-border">
      <div class="container-fluid">
        <div class="grid-container filmstro-top-row">
          <div class="filmstro-column col-md-4 col-lg-4 col-xl-3 pd-none filmstro-right-border">
            <slot name="top-left" />
          </div>

          <div class="noselect filmstro-column top-middle-column col-md-4 col-lg-4 col-xl-5 pd-none filmstro-right-border">
            <video-element/>
            <slot name="top-middle" />
          </div>

          <div class="noselect filmstro-column col-md-4 col-lg-4 col-xl-4 pd-none" v-show="enable_debug_for_admin">
            <slot name="top-right" />
          </div>
        </div>
      </div>
    </div>

    <div class="noselect info">
        <div class="info-section main-width filmstro-right-border filmstro-bottom-border">
          <slot name="middle-bar-section" />
        </div>
    </div>


    <div class="noselect filmstro-timeline-section" :class="{ 'mp3-files-loaded':mp3_files_loaded,'mp3-files-not-loaded':!mp3_files_loaded}">
      <div class="main-width filmstro-right-border filmstro-bottom-border">
        <slot name="bottom-section" />
      </div>
    </div>

    <footer>
      <div class="noselect main-width filmstro-right-border filmstro-bottom-border">
        <slot name="footer-section" />
      </div>
    </footer>
    <flyout-sidebar/>
    <slot name="modals" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import VideoElement from '../components/VideoElement.vue'
import FlyoutSidebar from '@/components/FlyoutSidebar.vue';
export default {
  props:{
    classes:{
      default:""
    }
  },
  computed:{
    ...mapGetters('edit',['mp3_files_loaded']),
    ...mapGetters(['enable_debug_for_admin'])
  },
  components:{
    VideoElement,
    FlyoutSidebar
  }
}
</script>