import API from "@/includes/API";
import { highlight, isMinore, splitQuery } from "@/includes/helpers";
import Fuse from 'fuse.js'

const options = {
  includeScore: true,
  threshold: 0.1,
  includeMatches: true,
  minMatchCharLength: 3,
  // ignoreLocation:true,
  useExtendedSearch: true,
  keys: [
    {
      name: 'title',
      weight: 50
    },
    {
      name: 'composer.name',
      weight: 10
    },
    {
      name: 'category.name',
      weight: 5
    },
    {
      name: 'search_terms.name',
      weight: 0.5
    }
  ]
};
const playlist_options = {
  includeScore: true,
  threshold: 0.5,
  includeMatches: true,
  minMatchCharLength: 3,
  // ignoreLocation:true,
  useExtendedSearch: true,
  keys: [
    {
      name: 'title',
      weight: 100
    },
    {
      name: 'description',
      weight: 0.3
    },
    {
      name: 'search_terms.name',
      weight: 10
    }
  ]
};

window.fuse_tracks = new Fuse([], options);
window.fuse_playlists = new Fuse([], playlist_options);

function maybeFilterTracksByEmotionalProfile(tracks, emotional_profile) {
  let negative = false;
  let positive = false;
  let balanced = false;
  let variable = false;
  let _tracks = tracks.filter(track => {
    if (emotional_profile.positive) {
      negative = !isMinore(track.key);
    }
    if (emotional_profile.negative) {
      positive = isMinore(track.key);
    }

    if (emotional_profile.balanced) {
      balanced = track.emotional_profiles.findIndex(e => e.slug.toLowerCase() == 'balanced') >= 0;
    }
    if (emotional_profile.variable == 'variable') {
      variable = track.emotional_profiles.findIndex(e => e.slug.toLowerCase() == 'variable') >= 0;
    }
    return positive || negative || balanced || variable;
  })
  return _tracks;
}


function filterTracksByEnergyLevel(tracks, energy_levels) {
  if (!energy_levels.length) {
    return tracks;
  }
  let _tracks = tracks.filter(track => {
    return track.energy_levels.some(e => energy_levels.includes(e.slug.toLowerCase()))
    return track.energy_levels.findIndex(e => {
      return energy_levels.indexOf(e.slug.toLowerCase())
    }) >= 0;
  })
  return _tracks;
}
function filterTracksbyVocals(tracks, filter) {


  if (filter.vocals && filter.instrumental || !filter.vocals && !filter.instrumental) {
    return tracks;
  }
  if (filter.vocals) {
    return tracks.filter(track => track.has_vocals == true)
  }
  else {
    return tracks.filter(track => track.has_vocals == false)
  }
  return tracks
}

function filterTracksByBPM(tracks, min, max) {
  min = parseInt(min);
  max = parseInt(max);

  let _tracks = tracks.filter(track => {
    let _track_bpm = parseInt(track.music_bpm);
    if (!_track_bpm) {
      return true;
    }
    if (min || max) {
      if (min && max) {
        return _track_bpm >= min && _track_bpm <= max;
      }
      if (min) {
        return _track_bpm >= min;
      }
      if (max) {
        return _track_bpm <= max;
      }
    }
    return true;
  })

  return _tracks;
}

function get_detault_browse_state() {
  return {
    search_query: "",
    search_categories: {},
    collections: [],
    categories: [],
    selected_collection: false,
    selected_album: false,
    music_cards: [],
    all_tracks: [],
    selected_theme: false,
    current_playing_theme: false,
    bread_crumbs: [],
    sync_music_with_video: true,
    limit_cards: 30,
    similar_track: null,
    selected_composer: null,
    music_tracks_view: 'list', //grid | list
    music_track_row_view: 'simple', //simple | advanced
    should_load_music_track: true,
    collections_filter_query: "",
    back_button_actions: {
      query: "",
      route: ""
    },
    filters: {
      mood: [],
      "music-genre": [],
      "video-genre": [],
      "instrumental-palette": [],
      "game-genre": [],

      search_tags: [],
      emotional_profile: {
        negative: false,
        positive: false,
        balanced: false,
        variable: false
      },
      energy_level: {
        high: false,
        low: false,
        medium: false,
      },
      vocals: {
        vocals: false,
        instrumental: false
      },
      bpm: {
        min: null,
        max: null
      }
    },
    set_tags_from_query: false,
    use_new_search: true,
    track_id_from_url: null,
    sorting_option: "default"
  }
}



let actions = {
  maybeIncreaseCardLimit(context, value) {
    // debugger
    if (context.rootState.all_tracks) {

    }
    context.commit('setLimitCards', value);
  },
  setBrowseData(context, browse_data, a, b, c) {

    if (browse_data.collections) {
      context.commit('setCollections', browse_data.collections);
      let pls = [];
      for (let index = 0; index < browse_data.collections.length; index++) {
        browse_data.collections[index].albums.forEach(album => {
          if (browse_data.collections[index].name !== 'composers') {
            pls.push(album);
          }
        })
      }

      window.fuse_playlists.setCollection(pls);

    }

    if (browse_data.categories) {
      Object.freeze(browse_data.categories);
      context.commit('setCategories', browse_data.categories);
    }
    if (browse_data.track_license_pricing) {
      Object.freeze(browse_data.track_license_pricing);

      context.commit('setTrackLicensePricing', browse_data.track_license_pricing, { root: true });
    }

    let all_tracks = get_all_tracks_from_collections(browse_data.collections);
    context.commit('setAllTracks', all_tracks);
    window.fuse_tracks.setCollection(all_tracks);

    // context.commit('setShowLoader', false, { root: true });
    window.calculateHeight();
  },
  async maybeFetchBrowseData(context, force_remote = false) {

    if (context.state.collections && context.state.collections.length && !force_remote) {
      return true;
    }
    // debugger
    let browse_data = await localforage.getItem('browse_data');
    if (!browse_data) {
      try {
        browse_data = require('../../public/data.json');
      } catch (error) {
        console.log('probably the /data.json doesnot exist?')
      }
    }
    let last_updated = 0;
    if (browse_data) {
      Object.freeze(browse_data);
      last_updated = browse_data.last_updated;

      context.dispatch('setBrowseData', browse_data);

      setTimeout(() => {
        context.dispatch('fetchBrowseData', browse_data.last_updated);
      }, 2000);

    } else {
      browse_data = await context.dispatch('fetchBrowseData', last_updated);
    }
  },
  async fetchBrowseData(context, last_updated = 0) {
    if (last_updated <= 0) {
      context.commit('setShowLoader', true, { root: true });
      context.commit('setLoaderMessage', 'Loading Filmstro Collections!', { root: true });
    }
    return await API.get(`/browse?last_updated=${last_updated}`).then(response => {
      if (response.status == 'success') {
        let data = response.data;
        if (data.use_new_format) {

          // debugger
          // let t = decryptData( response.data.tracks ,'perlat');

          data.collections = data.collections.map(collection => {
            if (collection.data_type === "albums") {
              collection.albums = collection.albums.map(album => {
                album.tracks = album.tracks.map(trackId => {
                  if (!data.tracks[trackId]) {
                  }
                  return data.tracks[trackId];
                });
                return album;
              }).filter(a => a !== false);
            }
            if (collection.data_type === "tracks") {
              collection.tracks = collection.tracks.map(trackId => {

                return data.tracks[trackId];
              }).filter(a => a !== false);
            }
            return collection
          });

          delete data.use_new_format;
          delete data.tracks;

        }

        context.dispatch('setBrowseData', data);

        localforage.setItem('browse_data', data).then(function () {
          return localforage.getItem('key');
        }).catch(function (err) {
          // we got an error
        });

        //context.commit('setShowLoader', false, { root: true });
        return data;
      }

    }).catch(e => {
      console.error('Something went wrong. Error ccode 1.', e);
    });
  },
  setDefaultCollectionAndFeaturedPlaylists(context, args = false) {

    let _default_collection = context.state.collections.find(col => col.name == "new");
    if (_default_collection) {
      context.commit('setSelectedCollection', _default_collection)
      context.commit('setMusicCards', _default_collection.tracks);
      context.commit('setSelectedAlbum', false);
      // context.commit('setBreadCrumbs', []);
      // if( args && args.query ){
      //   console.error('RESETTING QUERY ')
      // }
      context.commit('setSearchQuery', "");
      // context.commit('browse/setCurrentPlayingTheme', false);
    }
  }
}

let getters = {
  getSearchQuery(state) {
    return state.search_query;
  },
  collections_filter_query(state) {
    return state.collections_filter_query;
  },
  breadcrumbs(state) {
    return state.bread_crumbs;
  },
  search_categories(state) {
    return state.search_categories;
  },
  active_categories(state) {
    return Object.keys(state.search_categories).filter(d => state.search_categories[d])
  },
  active_emotional_profile(state) {
    return Object.keys(state.filters.emotional_profile).filter(d => state.filters.emotional_profile[d])
  },
  active_energy_level(state) {
    return Object.keys(state.filters.energy_level).filter(d => state.filters.energy_level[d])
  },
  sync_music_with_video(state) {
    return state.sync_music_with_video
  },
  limit_cards(state) {
    return state.limit_cards
  },
  should_load_music_track(state) {
    return state.should_load_music_track
  },

  collections(state) {
    return state.collections;
  },
  categories(state) {
    return state.categories;
  },

  selected_collection(state) {
    return state.selected_collection;
  },
  selected_album(state) {
    return state.selected_album;
  },
  playlists(state) {
    let query = state.search_query.trim().toLowerCase();
    let playlists = [];
    if (query.length) {
      let _query = splitQuery(query).join('|'); // query.split(/,| /).join('|');
      // let albums = []
      // we don't want composers to show as playlist blocks
      if (state.use_new_search) {
        console.log(_query,);
        let ppa = window.fuse_playlists.search(_query);
        playlists = ppa.map(a => {
          a.item.weight = 1;
          a.item.matches = a.matches
          return a.item;
        });
        return playlists;

        // console.log( playlists );
      } else {

        let collections = state.collections.filter(c => c.name !== 'composers');


        for (let index = 0; index < collections.length; index++) {
          collections[index].albums.forEach(album => {
            let title_search = album.title.toLowerCase().match(_query);
            let description_text = false
            let search_terms_search = false;
            if (!title_search) {
              search_terms_search = album.search_terms.filter(term => { return term.name.toLowerCase().match(_query) }).length;
              if (!search_terms_search) {
                description_text = album.description.toLowerCase().match(_query);
              }
            }

            if (title_search || search_terms_search || description_text) {
              let al = JSON.parse(JSON.stringify(album));
              //add the weitgh system
              if (title_search) {
                al.weight = 1;
                al.matched_key = title_search
              }
              if (search_terms_search) {
                al.weight = .5;
                al.matched_key = search_terms_search
              }
              if (description_text) {
                al.weight = .2;
                al.matched_key = description_text
                if (description_text['index']) {
                  al.weight = .2 - (description_text['index'] * 0.00001);
                  // we apply a wieght based on where the position of the keyword
                }
              }

              // al.collection = collections[ index ].name;
              playlists.push(al);
            }

            return false;
          });
          // playlists = playlists.concat( albums );
        }
      }


    }

    // sorting them by weight

    // playlists = playlists.sort((a,b)=> (a.weight < b.weight ? 1 : -1))
    playlists = [...new Map(playlists.map(item => [item['id'], item])).values()]
    return playlists.sort((a, b) => (a.weight < b.weight ? 1 : -1));
  },
  featured_playlists(state) {
    let featured_collection = state.collections.find(col => col.name == "featured");
    if (featured_collection && featured_collection.albums) {
      return JSON.parse(JSON.stringify(featured_collection.albums));
    } else {
      return [];
    }
  },
  music_cards(state, getters, rootState, rootgetters, aa, bb) {
    console.log( getters.activeCollectionsAlbumNames )
    let query = state.search_query.trim().toLowerCase();
    let _tracks = state.all_tracks;;
    if (state.selected_collection && state.selected_album) {
      _tracks = state.selected_album.tracks;
    }
    else if (state.selected_collection && state.selected_collection.data_type == 'tracks') {
      if( state.selected_collection.name !=='new' ){
        _tracks = state.selected_collection.tracks
      }
    }


    let cats = getters.active_categories;

    // Filter tracks by selected collection
    // if( getters.hasCollectionFilter ){
    //   _tracks = state.collections
    //   .filter(collection => state.filters[collection.name]?.length > 0)
    //   .flatMap(collection => {
    //     if (collection.data_type === 'albums') {
    //       return collection.albums
    //         .filter(album => state.filters[collection.name].includes(album.name))
    //         .flatMap(album => album.tracks)
    //     }
    //     return collection.tracks
    //   })
    //   _tracks = [...new Map(_tracks.map(track => [track.id, track])).values()]
    // }
    if (getters.hasCollectionFilter) {
      // First, get tracks from each collection separately
      const tracksByCollection = state.collections
        .filter(collection => state.filters[collection.name]?.length > 0)
        .map(collection => {
          if (collection.data_type === 'albums') {
            return collection.albums
              .filter(album => state.filters[collection.name].includes(album.name))
              .flatMap(album => album.tracks)
          }
          return collection.tracks
        })

      // Find tracks that exist in all collections (intersection)
      if (tracksByCollection.length > 0) {
        // Convert first collection's tracks to a Map for deduplication
        _tracks = [...new Map(tracksByCollection[0].map(track => [track.id, track])).values()]

        // Intersect with remaining collections
        for (let i = 1; i < tracksByCollection.length; i++) {
          const currentCollectionTrackIds = new Set(tracksByCollection[i].map(track => track.id))
          _tracks = _tracks.filter(track => currentCollectionTrackIds.has(track.id))
        }
      } else {
        _tracks = []
      }
    }

    // Filter tracks by selected composer
    if (typeof state.selected_composer !== 'undefined' && state.selected_composer) {
      _tracks = state.all_tracks.filter(track => {
        if (typeof state.selected_composer !== 'undefined') {
          return track.composer.id == state.selected_composer.id;
        }
      })
    }

    let energy_level = getters.active_energy_level;
    let vocals = state.filters.vocals;

    if (query.length) {
      let _query = query.split(/,/).join("|");

      if (state.use_new_search) {
        _query = splitQuery(query).join('|');
        let tt = window.fuse_tracks.search(_query);
        _tracks = tt.map(a => {
          return {
            ...a.item,
            matches: a.matches
          }
        })
      } else {
        _tracks = state.all_tracks.filter((track) => {
          track.matches = [];
          track.weight = 0;
          let search_terms_search = [];
          let composer_search = false;
          let qqs = _query.split('|');
          let title_search = qqs.includes(track.title.toLowerCase());

          if (!title_search) {

            search_terms_search = track.search_terms.filter(term => { return term.name.toLowerCase().match(_query) })
            if (!search_terms_search.length) {
              composer_search = track.composer.name.toLowerCase().match(_query);
            }

          }

          if (title_search) {
            track.weight = 1;
            track.matches.push({ key: 'title', value: track.title });
          }
          if (search_terms_search.length) {
            track.weight = .75;
            search_terms_search.forEach(term => { track.matches.push({ key: 'search_term.name', value: term.name }); });
          }
          if (composer_search) {
            track.weight = .5;
            track.matches.push({ key: 'composer.name', value: track.composer.name });
          }
          if (title_search || search_terms_search.length || composer_search) {
            return true;
          }
          // return  category_search || title_search || search_terms_search.length;
          return false;
        }).sort((a, b) => (a.weight < b.weight ? 1 : -1))
      }
    }

    if (cats.length) {
      _tracks = _tracks.filter(track => {
        return track.category.name && cats.includes(track.category.name);
      })
    }
    // console.log(`Total Tracks ${_tracks.length}`);
    _tracks.filter(track => { return track.clear_music.length && track.watermark_music.length; })

    if (getters.active_emotional_profile.length) {
      _tracks = maybeFilterTracksByEmotionalProfile(_tracks, state.filters.emotional_profile);
    }

    if (energy_level.length) {
      _tracks = filterTracksByEnergyLevel(_tracks, energy_level);
    }
    if (vocals) {
      _tracks = filterTracksbyVocals(_tracks, vocals);
    }

    if (state.filters.bpm) {
      let min = parseInt(state.filters.bpm.min);
      let max = parseInt(state.filters.bpm.max);
      _tracks = filterTracksByBPM(_tracks, min, max);
    }


    if (state.sorting_option == 'BPM_ASC' || state.sorting_option == 'BPM_DESC') {
      _tracks.sort((a, b) => {
        let bpm_a = Number(a.music_bpm)
        let bpm_b = Number(b.music_bpm)
        if (bpm_a && bpm_b) {
          return state.sorting_option === 'BPM_ASC' ? (bpm_a > bpm_b ? 1 : -1) : (bpm_a < bpm_b ? 1 : -1)
        }
        return true;
      });
    }

    if (state.sorting_option == 'NAME_ASC' || state.sorting_option == 'NAME_DESC') {
      _tracks.sort((a, b) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()
        return state.sorting_option === 'NAME_ASC'  ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA)
      })
    }

 // return _tracks;
    if (state.track_id_from_url) {
      let track_index = _tracks.findIndex(t => t.id == state.track_id_from_url)
          if (track_index >= 0) {
            const [track] = _tracks.splice(track_index, 1);
            if (track) {
              _tracks.unshift(track);
            }

          }
    }
    return _tracks;//.slice(0, state.limit_cards)
  },
  music_tracks_spliced(state, getters) {
    return getters.music_cards.slice(0, state.limit_cards)
  },

  current_playing_theme(state) {
    return state.current_playing_theme;
  },


  selected_theme(state) {
    return state.selected_theme;
  },
  similar_track(state) {
    return state.similar_track;
  },
  selected_composer(state) {
    return state.selected_composer;
  },
  music_tracks_view(state) {
    return state.music_tracks_view;
  },
  music_track_row_view(state) {
    return state.music_track_row_view;
  },
  filters(state) {
    return state.filters;
  },
  emotional_profile(state) {
    return state.filters.emotional_profile;
  },
  energy_level(state) {
    return state.filters.energy_level;
  },
  vocals(state) {
    return state.filters.vocals;
  },
  minimum_bpm(state) {
    return state.filters.bpm.min;
  },
  maximum_bpm(state) {
    return state.filters.bpm.max;
  },
  back_button_actions(state) {
    return state.back_button_actions;
  },
  has_filters_enabled(state) {
    let has_categories = Object.values(state.search_categories).filter(a => a).length > 0;
    let has_emotional_profile = state.filters.emotional_profile.length > 0;
    let has_energy_level = state.filters.energy_level.length > 0;
    let has_vocals = state.filters.vocals.vocals == true || state.filters.vocals.instrumental == true;
    let has_bpm = !!state.filters.bpm.min || state.filters.bpm.max;
    return has_categories || has_emotional_profile || has_energy_level || has_bpm || has_vocals;
  },
  all_tracks(state) {
    return state.all_tracks;
  },
  use_new_search: state => state.use_new_search,
  track_id_from_url: state => state.track_id_from_url,
  sorting_option: state => state.sorting_option,
  hasFilter: (state) => (filterName) => {
    return state.filters[filterName]?.length > 0 || false
  },
  hasCollectionFilter: (state) => {
    const collections = ['mood', 'music-genre', 'video-genre', 'instrumental-palette', 'game-genre']
    return collections.some(collection => state.filters[collection]?.length > 0)
  },
  activeCollections: (state) => {
    const collections = ['mood', 'music-genre', 'video-genre', 'instrumental-palette', 'game-genre']
    return collections.filter(collection => state.filters[collection]?.length > 0)
  },
  activeCollectionsAlbumNames: (state) => {
    const collections = ['mood', 'music-genre', 'video-genre', 'instrumental-palette', 'game-genre'].filter(collection => state.filters[collection]?.length > 0)

    return state.collections
      .filter(collection => state.filters[collection.name]?.length > 0)
      .flatMap(collection => {
        if (collection.data_type === 'albums') {
          return collection.albums .filter(album => state.filters[collection.name].includes(album.name))
            // .flatMap(album => album.tracks)
        }
        // return collection.tracks
      })

    return state.collections
        .filter(collection => state.filters[collection.name]?.length > 0)
        .map(collection => {
          if (collection.data_type === 'albums') {
            return collection.albums
              .filter(album => state.filters[collection.name].includes(album.name))
              .flatMap(album => album.tracks)
          }
          return collection.tracks
        })
    return collections;
  },
  isInFilter: (state) => (filterName, item) => {
    return state.filters[filterName]?.includes(item) || false
  }


}

let mutations = {
  setCollections(state, collections) {
    state.collections = collections;
  },
  setCategories(state, categories) {

    state.categories = categories;
    let _cats = {};
    categories.forEach(cat => {
      _cats[cat.name] = false;
    })
    state.search_categories = _cats;
  },
  setSelectedAlbum(state, album) {
    state.selected_album = album;
  },

  setSelectedCollection(state, collection) {
    state.selected_collection = collection;
  },

  setCurrentPlayingTheme(state, theme) {
    state.current_playing_theme = theme;
  },
  setAllTracks(state, data) {
    var unique = [];
    var distinct = [];
    for (let i = 0; i < data.length; i++) {
      if (!unique[data[i].id]) {
        distinct.push(data[i]);
        unique[data[i].id] = 1;
      }
    }
    state.all_tracks = distinct;
  },
  setSearchQuery(state, query) {
    state.search_query = query
  },
  //just an action to subscribe functions
  setTagsFromQuery(state, q) {
    state.set_tags_from_query = q;
    return true;
  },
  setCollectionsSearchQuery(state, query) {
    state.collections_filter_query = query;
  },
  setSearchCategories(state, cats) {
    state.search_categories = cats
  },
  toggleSearchCategory(state, cat) {
    state.search_categories[cat] = !state.search_categories[cat];
  },
  toggleVocalsFilter(state, cat) {
    state.filters.vocals[cat] = !state.filters.vocals[cat];
  },
  toggleEmotionalProfileFilter(state, profile) {
    state.filters.emotional_profile[profile] = !state.filters.emotional_profile[profile]
  },
  toggleEnergyLevelFilter(state, profile) {
    state.filters.energy_level[profile] = !state.filters.energy_level[profile]

  },
  toggleCollectionAlbum(state, { col, album }) {
    // If the collection exists in filters
    if (col in state.filters) {
      const index = state.filters[col].indexOf(album)
      // state.filters[col]=[album]
      // return;

      if (index === -1) {
        // Album not found - add it
        // state.filters[col].push(album)
        state.filters[col]=[album]
      } else {
        // Album found - remove it
        state.filters[col]=[]
        // state.filters[col].splice(index, 1)
      }
    }
  },

  addSearchTags(state, tags) {
    if (tags.length) {
      state.filters.search_tags = tags
    }
  },
  resetSearchCategories(state) {
    Object.keys(state.search_categories).forEach(d => {
      state.search_categories[d] = false;
    });
  },
  resetCollectionFilter(state, col) {
    state.filters[col] = [];
  },
  resetEmotionalProfile(state) {
    Object.keys(state.filters.emotional_profile).forEach(d => {
      state.filters.emotional_profile[d] = false;
    });
  },
  resetEnergyLevel(state) {
    Object.keys(state.filters.energy_level).forEach(d => {
      state.filters.energy_level[d] = false;
    });
  },
  resetVocalsFilter(state) {
    state.filters.vocals = {
      vocals: false,
      instrumental: false
    }
    // Object.keys(state.search_categories).forEach(d => {
    //   state.filter.vocals[d] = false;
    // });
  },
  setBreadCrumbs(state, bread_crumbs) {

    state.bread_crumbs = bread_crumbs
  },
  resetState(state) {
    let x = get_detault_browse_state()
    Object.assign(state, x);
  },
  toggleSyncMusicWithVideo(state) {
    state.sync_music_with_video = !state.sync_music_with_video;
  },
  setLimitCards(state, value) {
    state.limit_cards = value;
  },
  setShouldLoadMusicTrack(state, value) {
    state.should_load_music_track = value;
  },
  resetTrackCardsLimit(state, value) {
    let defaults = get_detault_browse_state()
    state.limit_cards = defaults.limit_cards;
  },
  setSelectedTheme(state, theme) {
    state.selected_theme = theme;
  },
  setMusicCards(state, tracks) {
    state.music_cards = tracks;
  },
  setSimilarTrack(state, track) {
    state.similar_track = track;
  },
  setSelectedComposer(state, composer) {
    state.selected_composer = composer;
  },
  setMusicTracksView(state, view) {
    state.music_tracks_view = view;
  },
  setMusicTrackRowView(state, view) {
    state.music_track_row_view = view;
  },
  setComposerBySlug(state, slug) {
    if (slug) {
      let _track = state.all_tracks.find(track => {
        return track.composer.slug == slug;
      })
      if (typeof _track !== 'undefined' && _track.composer) {
        state.selected_composer = _track.composer;
      }
    }
  },
  setMinimumBPM(state, bpm) {
    state.filters.bpm.min = bpm;
  },
  setMaximumBPM(state, bpm) {
    state.filters.bpm.max = bpm;
  },
  setRouteForBackButton(state, path) {
    state.back_button_actions.route = path;
  },
  setQueryForBackButton(state, q) {
    state.back_button_actions.query = q;
  },
  setUseNewSearch(state, value) { state.use_new_search = value; },
  setTrackIdFromURL(state, value) { state.track_id_from_url = value; },
  setSortingOption(state, value) { state.sorting_option = value; }



}




function get_all_tracks_from_collections(collections) {

  let tracks = [];
  let track_ids = [];
  collections.forEach(d => {
    if (d.tracks) {
      d.tracks.forEach(t => {
        if (t) {
          if (!track_ids.includes(t.id) && t.clear_music.length && t.watermark_music.length) {
            tracks.push(t);
            track_ids.push(t.id);
          } else {
            if (!t.clear_music.length) {
              console.error(`Track ${t.id} does not have clear music`)
            }
            if (!t.watermark_music.length) {
              console.error(`Track ${t.id} does not have watermark music`)
            }
          }
        } else {
          debugger
        }
      })
    }
    if (d.albums) {
      d.albums.forEach(a => {
        a.tracks.forEach(tt => {
          try {
            if (tt) {

              if (!track_ids.includes(tt.id) && tt.clear_music.length && tt.watermark_music.length) {

                tracks.push(tt);
                track_ids.push(tt.id);
              }
            } else {
              if (!tt.clear_music.length) {
                console.error(`Track ${t.id} does not have clear music`)
              }
              if (!tt.watermark_music.length) {
                console.error(`Track ${t.id} does not have watermark music`)
              }
            }

          } catch (e) {
            console.log('errs', a.tracks)
            console.log(e)
          }
        })
      })
    }
  });

  track_ids = null;
  return tracks.sort((a, b) => (a.id < b.id ? 1 : -1));;
}




export default {
  namespaced: true,
  state: get_detault_browse_state(),
  getters,
  mutations,
  actions
}

