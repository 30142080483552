import AudioEngineClass from '@/library/AudioEngine';
import { ref, onUnmounted } from 'vue';

// Create singleton instance and refs outside the composable
const AudioEngine = AudioEngineClass;
const is_playing = ref(false);
const selected_inflection_index = ref(0);
const formatted_time = ref("00:00:00");

// Track if listeners are already set up
let listenersInitialized = false;

export default function useAudioEngine() {
    if (!listenersInitialized) {
        // Set up listeners only once
        AudioEngine.on('play-status', (e) => {
            let { playing } = e.detail;
            is_playing.value = playing;
        });

        AudioEngine.on('set-current-time', (e) => {
            let { time } = e.detail;
            formatted_time.value = time;
        });

        listenersInitialized = true;

        // Clean up listeners when the last instance is destroyed
        onUnmounted(() => {
            // Optional: Add logic to check if this is the last instance
            // For example, you could keep a counter of active instances
            AudioEngine.off('play-status');
            AudioEngine.off('set-current-time');
            listenersInitialized = false;
        });
    }

    const { setCoordValue, getCoordValue, setSliderValue, updateSvgPaths, calculateValuesFoMusicSeconds } = AudioEngine;

    return {
        is_playing,
        formatted_time,
        selected_inflection_index,
        setCoordValue,
        getCoordValue,
        setSliderValue,
        updateSvgPaths,
        calculateValuesFoMusicSeconds,
        AudioEngine
    }
}