import { setInfoTooltip } from "@/includes/helpers";

// Keep track of the last set tooltip content and timestamp
const tooltipState = {
    lastContent: null,
    lastTimestamp: null,
    timeoutId: null
};

export default {
    created(el, binding) {
        // console.log('Created....');
    },

    mounted(el, binding) {
        const getTooltipTarget = () => {
            const dataTarget = el.getAttribute('data-target');
            if (dataTarget) {
                return document.querySelector(dataTarget);
            }
            return document.querySelector('#timeline-tooltips');
        };

        const clearTooltipIfUnchanged = (content, element) => {
            // Only clear if this content was the last one set
            if (tooltipState.lastContent === content) {
                setInfoTooltip('', element);
                tooltipState.lastContent = null;
                tooltipState.lastTimestamp = null;
            }
        };

        el.addEventListener('mouseenter', () => {
            const demoElement = getTooltipTarget();
            if (demoElement) {
                let v = binding.value;
                if (typeof v == 'object' && v !== null) {
                    v = binding.value.content;
                }

                // Clear any existing timeout
                if (tooltipState.timeoutId) {
                    clearTimeout(tooltipState.timeoutId);
                }

                // Set the new tooltip
                setInfoTooltip(v, demoElement);

                // Update state
                tooltipState.lastContent = v;
                tooltipState.lastTimestamp = Date.now();

                // Set new timeout
                tooltipState.timeoutId = setTimeout(() => {
                    clearTooltipIfUnchanged(v, demoElement);
                }, 5000);
            }
        });

        el.addEventListener('mouseleave', () => {
            // Mouseleave logic if needed
        });

        // Cleanup function
        el._tooltipCleanup = () => {
            if (tooltipState.timeoutId) {
                clearTimeout(tooltipState.timeoutId);
            }
        };
    },

    unmounted(el) {
        if (el._tooltipCleanup) {
            el._tooltipCleanup();
        }
    }
}