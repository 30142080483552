<template>
    <div class="about-filmstro">
        <div class="about-header">
            <img loading="lazy" src="@/assets/filmstro-logo.png" class="filmstro-logo" alt="logo">
            <h3>Filmstro Pro</h3>
        </div>
        <div class="about-version"><small>v{{ $version }}</small></div>
        <div class="about-copyright">©2014-{{ new Date().getFullYear() }} Filmstro. All rights reserved.</div>
        <div class="about-credits">
            <span>Sebastian Jaeger</span>, <span>Perlat Kociaj</span>, <span>Christopher Young</span>, <span>Timothy
                Fletcher</span>, <span>Dirk Krause</span>
        </div>
        <div class="about-terms">
            <a target="_blank" href="https://filmstro.com/terms-and-conditions">Terms And Conditions</a>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.about-filmstro {
    font-size: 16px;
    display: flex;
    padding: 30px;
    flex-direction: column;
    justify-content: space-evenly;
    justify-content: space-between;
    gap: 20px;
    text-align: left;
}

.about-header {
    display: flex;
    align-items: center;
    img{
        width: 50px;
    }
    h3{
        margin-left: 20px;
        font-size: 40px;
        color: white;
    }
}
.about-terms{
    a{
        color: var(--filmstro-gray);
        text-decoration: underline;
    }
}
</style>