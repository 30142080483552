export const vAnimation = {
    created(el, binding) {
        el.classList.add('animated-element');
    },

    beforeMount(el, binding) {
        // Store animation function on element
        el._animateElement = (animationType = binding.arg || 'pulse') => {
            // Remove any existing animation classes
            el.classList.remove('pulse', 'ripple', 'bounce', 'press');

            // Force a reflow
            void el.offsetWidth;

            // Add the new animation class
            el.classList.add(animationType);

            // Remove animation class after it completes
            setTimeout(() => {
                el.classList.remove(animationType);
            }, 500);
        };

        // Handle click animations if no watch value is provided
        if (!binding.value) {
            el._clickHandler = () => el._animateElement();
            el.addEventListener('click', el._clickHandler);
        }
    },

    updated(el, binding) {
        // If we have a value to watch, trigger animation when it changes
        if (binding.value !== binding.oldValue && binding.value !== undefined) {
            el._animateElement();
        }
    },

    unmounted(el) {
        if (el._clickHandler) {
            el.removeEventListener('click', el._clickHandler);
            delete el._clickHandler;
        }
        delete el._animateElement;
    }
};