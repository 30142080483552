<template>
  <div class="video-box-container" :class="{ 'no-bg': video_source }"
    :style="`--size:${Math.round(zoom_level * 20)}px`">
    <div class="video-box-wrapper"
      v-tooltip="{ content: `You can add an mp4 video which will play back synchronised to each track you preview while you're browsing music.`, placement: 'bottom' }">

      <div v-show="!video_source" class="no-video-wrapper" @click="openUploadDialogue" title="Add/replace video"
        v-tooltip="{ followCursor: true, content: `Add video. Max size 500 MB.`, placement: 'right' }">
        <span>Upload a video to audition <br> music to your edit</span>
        <img src="@/assets/images/film-image.png" alt="">

      </div>

      <div class="video-box" v-show="video_source">
        <div class="video-options">
          <button @click="deleteCurrentVideo" class="clear-button">
            <filmstro-icon title="Delete current video" name='video-remove' class="add-video" />
          </button>
          <span class="vertical-separator"></span>
          <button @click="openUploadDialogue" class="clear-button">
            <filmstro-icon title="Add/replace video" name='transfer' class="add-video-active" />
          </button>
        </div>

        <div class="video-container" ref="videoContainerRef" @wheel.prevent="handleZoom" @mousedown="startDrag"
          @dblclick="toggleZoomToPoint">
          <video :data-name="fileDetails.name" ref="videoElementRef" :style="videoStyle" key="the_video_element"
            :muted="isVideoMuted" @loadedmetadata="onLoadedMetaData" @ended="onVideoEnded" preload="auto" @play="onPlay"
            @pause="onPause" :src="video_source" @timeupdate="handleTimeUpdate" id="video-el2">
          </video>
        </div>

        <div class="video-controls" ref="videoControlsRef" :class="{ 'show-controls': isPlaying || isHoveringControls }"
          @mouseenter="isHoveringControls = true" @mouseleave="isHoveringControls = false"
          v-tooltip="`Video volume and mute: The video audio is not included in the final exported music`">

          <button class="video-play-toggle clear-button" @click="toggleVideo" v-if="false">
            <filmstro-icon :name="isPlaying ? 'pause' : 'play'" />
          </button>

          <div v-if="false" class="video-progress" ref="progressContainerRef" @mouseup="handleProgressMouseUp"
            @mousemove="handleProgressMouseMove" @mousedown="handleProgressMouseDown">
            <div ref="progressBarRef" class="video-progress--filled"></div>
          </div>

          <div class="video-time-wrapper">
            <time ref="videoTimeRef" class="video-time">00:00</time> /
            <time ref="videoDurationRef" class="video-duration">00:00</time>
          </div>

          <div class="zoom-options">
            <button @click="fitToContainer" class="clear-button">
              <filmstro-icon title="Fit to screen" name='fit-to-container' class="add-video-active" />
            </button>
            <button @click="resetZoom" class="clear-button">
              <filmstro-icon title="Full Width" name='maximize-2' class="add-video-active" />
            </button>
            <div class="zoom-input-group" v-if="route.name == 'edit'">
              <select :value="zoom_dropdown_value" @change="handleZoomChange" class="zoom-select">
                <option value="fit">Fit</option>
                <option value="full">Fill</option>
                <option value="10">10%</option>
                <option value="25">25%</option>
                <option value="50">50%</option>
                <option value="75">75%</option>
                <option value="125">125%</option>
                <option value="150">150%</option>
                <option value="200">200%</option>
                <option v-if="zoom_dropdown_value == 'custom'" value="custom">{{ Math.round(zoom_level * 100) }}%
                </option>
              </select>
              <div class="zoom-icon">
                <filmstro-icon name="zoom-plus" size="14" />
              </div>
            </div>
          </div>





          <div class="flex">
            <div class="volume-control">
              <range-slider class="video-volume-slider" :value="video_volume"
              :min="0"
              :max="1"
              :step="0.01" @input="setVideoVolume($event)"
              @changed="onVideoVolumeChange($event)" />
            </div>
            <button class="video-mute-toggle clear-button" @click="toggleMute">

              <filmstro-icon name="speaker-normal" v-show="!isVideoMuted" />
              <filmstro-icon name="speaker-muted" v-show="isVideoMuted" />
            </button>
          </div>

        </div>

        <input id="video-file-input" @change="onFileUploadChange" hidden ref="fileInputRef" type="file"
          accept="video/mp4">
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import DragDropit from '@/includes/DragDropit'
import RangeSlider from '@/components/RangeSlider.vue'
import { format_size } from '@/includes/helpers'
import { useRoute } from 'vue-router'

// Store setup
const store = useStore()
const route = useRoute();
// Refs
const videoContainerRef = ref(null)
const videoElementRef = ref(null)
const videoControlsRef = ref(null)
const progressContainerRef = ref(null)
const progressBarRef = ref(null)
const videoTimeRef = ref(null)
const videoDurationRef = ref(null)
const fileInputRef = ref(null)

// State
const isPlaying = ref(false)
const isVideoMuted = ref(true)
const videoVolume = ref(1)
const isHoveringControls = ref(false)
const isMouseDown = ref(false)
const fileDetails = ref({ name: "", size: '' })

// Zoom state
const zoom_level = ref(1)
const minZoom = ref(0.1)
const maxZoom = ref(5)
const defaultZoom = ref(1)
const isDragging = ref(false)
const dragStart = ref({ x: 0, y: 0 })
const videoPosition = ref({ x: 0, y: 0 })
const lastZoomPoint = ref(null)


const video_source = computed(() => store.getters.video_source)
const video_volume = computed(() => store.getters.video_volume)


const videoStyle = computed(() => ({
  transform: `scale(${zoom_level.value}) translate(${videoPosition.value.x}px, ${videoPosition.value.y}px)`,
  transition: isDragging.value ? 'none' : 'transform 0.2s ease'
}))


const zoom_dropdown_value = computed(() => {
  if (zoom_level.value === defaultZoom.value) return 'full'
  const percentage = Math.round(zoom_level.value * 100)
  if ([10, 25, 50, 75, 125, 150, 200].includes(percentage)) {
    return percentage.toString()
  }

  const video = videoElementRef.value
  if (video) {
    const container = videoContainerRef.value
    const container_ratio = container.clientWidth / container.clientHeight
    const video_ratio = video.videoWidth / video.videoHeight
    const fitZoom = container_ratio > video_ratio
      ? container.clientHeight / video.videoHeight
      : container.clientWidth / video.videoWidth

    if (Math.abs(zoom_level.value - fitZoom) < 0.01) return 'fit'
  }

  return 'custom'
})

const handleZoomChange = (event) => {
  const value = event.target.value

  switch (value) {
    case 'fit':
      fitToContainer()
      break
    case 'full':
      resetZoom()
      break
    default:
      // Handle percentage values
      const percentage = parseInt(value, 10)
      if (!isNaN(percentage)) {
        zoom_level.value = percentage / 100
        videoPosition.value = { x: 0, y: 0 } // Reset position when changing zoom
      }
  }
}
// Methods
const formatTime = (time) => {
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)
  return `${minutes}:${seconds.toString().padStart(2, '0')}`
}

// Zoom Methods
const handleZoom = (event) => {
  if (!video_source.value) return

  const delta = event.deltaY * -0.001
  const newZoom = Math.min(Math.max(zoom_level.value + delta, minZoom.value), maxZoom.value)

  const container = videoContainerRef.value.getBoundingClientRect()
  const x = container.width / 2 - container.left
  const y = container.height / 2 - container.top

  zoomToPoint(newZoom, { x, y })
}

const zoomToPoint = (newZoom, point) => {
  const scaleChange = newZoom / zoom_level.value
  const dx = (point.x - videoPosition.value.x) * (1 - scaleChange)
  const dy = (point.y - videoPosition.value.y) * (1 - scaleChange)

  zoom_level.value = newZoom
  videoPosition.value = {
    x: videoPosition.value.x + dx,
    y: videoPosition.value.y + dy
  }
}

const toggleZoomToPoint = () => {

  zoom_level.value !== defaultZoom.value ? resetZoom() : fitToContainer()

}

const resetZoom = () => {
  zoom_level.value = defaultZoom.value
  videoPosition.value = { x: 0, y: 0 }
}

const fitToContainer = () => {
  if (!videoElementRef.value || !video_source.value) return

  const container = videoContainerRef.value
  const video = videoElementRef.value

  const container_aspect_ratio = container.clientWidth / container.clientHeight
  const video_ratio = video.videoWidth / video.videoHeight

  zoom_level.value = container_aspect_ratio > video_ratio
    ? container.clientHeight / video.videoHeight
    : container.clientWidth / video.videoWidth

  videoPosition.value = { x: 0, y: 0 }
}

// Pan Methods
const startDrag = (event) => {
  if (!video_source.value || event.button !== 0) return

  isDragging.value = true
  dragStart.value = {
    x: event.clientX - videoPosition.value.x,
    y: event.clientY - videoPosition.value.y
  }

  document.addEventListener('mousemove', handleDrag)
  document.addEventListener('mouseup', endDrag)
}

const handleDrag = (event) => {
  if (!isDragging.value) return

  videoPosition.value = {
    x: event.clientX - dragStart.value.x,
    y: event.clientY - dragStart.value.y
  }
}

const endDrag = () => {
  isDragging.value = false
  document.removeEventListener('mousemove', handleDrag)
  document.removeEventListener('mouseup', endDrag)
}

// Video Control Methods
const onPlay = () => isPlaying.value = true
const onPause = () => isPlaying.value = false

const toggleVideo = () => {
  const video = videoElementRef.value
  if (video.paused) {
    video.play()
  } else {
    video.pause()
  }
}

const handleTimeUpdate = () => {
  if (!videoElementRef.value) return

  const video = videoElementRef.value
  const progress = (video.currentTime / video.duration) * 100

  if (progressBarRef.value) {
    progressBarRef.value.style.flexBasis = `${progress}%`
  }

  updateTimeDisplay()
}

const updateTimeDisplay = () => {
  const video = videoElementRef.value
  if (!video?.readyState) return

  if (videoTimeRef.value) {
    videoTimeRef.value.textContent = formatTime(video.currentTime)
  }
  if (videoDurationRef.value) {
    videoDurationRef.value.textContent = formatTime(video.duration)
  }
}

// Progress Control Methods
const handleProgressMouseMove = (event) => {
  if (!isMouseDown.value) return
  setVideoProgress(event)
}

const handleProgressMouseUp = () => {
  isMouseDown.value = false
}

const handleProgressMouseDown = (event) => {
  isMouseDown.value = true
  setVideoProgress(event)
}

const setVideoProgress = (event) => {
  const progress = progressContainerRef.value
  const scrubTime = (event.offsetX / progress.offsetWidth) * videoElementRef.value.duration
  videoElementRef.value.currentTime = scrubTime
}

// Volume Control Methods
const setVideoVolume = (event) => {
  debugger
  console.log('setVideoVolume', event);
  const value = parseFloat( event.target.value )
  videoVolume.value = value;
  videoElementRef.value.volume = videoVolume.value
  isVideoMuted.value = value === 0
  videoElementRef.value.muted = isVideoMuted.value
}
const onVideoVolumeChange = (value)=>{
  debugger
  console.log('onVideoVolumeChange', value);
  const volume = parseFloat( value )
  store.commit('setVideoVolume', Number( volume ));
}

const toggleMute = () => {
  isVideoMuted.value = !isVideoMuted.value
  videoElementRef.value.muted = isVideoMuted.value
}

// File Handling Methods
const deleteCurrentVideo = () => {
  if (fileInputRef.value) fileInputRef.value.value = null
  store.commit('setVideoSource', '')
  store.commit('setVideoFileInfo', { name: '', size: '' })
  resetZoom()
  setTimeout(() => {
    document.dispatchEvent(new CustomEvent('video-deleted', { detail: { duration:videoElementRef?.value?.duration || 0, file_details: fileDetails.value, video_element: fileInputRef.value } }));
  }, 100);
}

const openUploadDialogue = () => {
  fileInputRef.value?.click()
}

const onFileUploadChange = (event) => {
  console.log(event)
  handleVideoUpload(event.target.files)
}

const handleVideoUpload = (files) => {
  console.log(files)
  const file = files[0]
  if (!file) return

  fileDetails.value = {
    name: file.name,
    size: file.size
  }

  store.commit('setShowLoader', true)
  store.commit('setLoaderMessage', `Loading ${fileDetails.value.name}`)
  store.commit('setVideoFileInfo', fileDetails.value)

  const localUrl = URL.createObjectURL(file)
  store.commit('setVideoSource', localUrl)

  if (localUrl) {
    if (route.name == 'edit') {
      setTimeout(() => {
        document.dispatchEvent(new CustomEvent('video-changed', { detail: { file_details: fileDetails.value, video_element: fileInputRef.value } }));
      }, 500)
    }
  }

  setTimeout(() => {
    store.commit('setShowLoader', false)
    resetZoom()
  }, 350)
}

const onLoadedMetaData = () => {
  updateTimeDisplay()
  // document.dispatchEvent(new CustomEvent('video-metadata-changed', { detail: { duration:videoElementRef?.value?.duration || 0, file_details: fileDetails.value, video_element: fileInputRef.value } }));

}

const onVideoEnded = () => {
  const video = videoElementRef.value
  if (!video) return

  video.currentTime = video.duration
  updateTimeDisplay()
  isPlaying.value = false
}



// Lifecycle hooks
onMounted(() => {
  // Initialize video element
  setTimeout(() => {
    const videoEl = videoElementRef.value
    store.commit('setVideoElement', videoEl)
    onLoadedMetaData()
  }, 300)

  // store.commit('setVideoSource', 'https://github.com/gregoriB/custom-HTML5-video-player-Javascript/blob/master/video.mp4?raw=true');
  // store.commit('setVideoFileInfo', {
  //   name: 'Demo',
  //   size: 123123
  // });




  window.addEventListener('resize', resetZoom);
  // Setup drag and drop
  const dropArea = new DragDropit('.video-box-wrapper')
  if (dropArea?.element) {
    dropArea.element.addEventListener('dropit.drop', (event) => {
      if (event.detail) {
        if (event.detail[0].type.includes('video')) {
          handleVideoUpload(event.detail)
        } else {
          store.commit('setNotification', {
            type: 'error',
            title: 'Error',
            message: 'File format is wrong. Please upload a video'
          })
        }
      }
    })
  }

})

onUnmounted(() => {
  const video = videoElementRef.value
  if (video) {
    video.pause()
    isPlaying.value = false
    isVideoMuted.value = true
  }

  window.removeEventListener('resize', resetZoom);
})

// Watch for video source changes
watch(video_source, (newSource) => {
  if (newSource) {
    nextTick(() => {
      fitToContainer()
    })
  }
})
</script>

<style scoped lang="scss">
.video-box-container {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  flex-wrap: wrap;
  align-items: center;

  &.no-bg {
    background-image: none;
  }


}

.video-box-wrapper {
  height: 100%;
  width: 100%;
  transition: all .5s ease;
  display: flex;
  align-items: center;
}

.video-box {
  position: relative;
  transition: all .5s ease;
  max-width: calc(100% - 20px);
  max-height: calc(100% - 20px);
  margin: 0 auto;
  overflow: hidden;
  height: 100%;
  width: 100%;

  &:hover {
    .video-controls {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    .video-options {
      opacity: 1;
    }
  }
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  // background: #1a1a1a;
  background-image: linear-gradient(to right, #80808012 1px, transparent 1px),
    linear-gradient(to bottom, #80808012 1px, transparent 1px);
  background-size: 24px 24px;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

video {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  transform-origin: center center;
  pointer-events: none;
  object-fit: contain;
}

.video-controls {

  right: 0;

  z-index: 10;
  background: rgba(0, 0, 0, 0.8);
  border-top: 5px solid rgba(0, 0, 0, 0.02);
  border-bottom: 5px solid rgba(0, 0, 0, 0);
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  margin: 0;
  transition: .3s ease;
  transition-property: opacity, visibility, transform;
  transform: translateY(50px);
  visibility: hidden;
  width: 100%;
  padding: 5px 10px;
  gap: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  &.show-controls {
    opacity: 1;
    visibility: visible;
  }
}

.video-progress {
  flex: 1;
  height: 4px;
  background: rgba(255, 255, 255, 0.2);
  position: relative;
  cursor: pointer;
  border-radius: 2px;

  &:hover {
    height: 6px;

    .video-progress--filled {
      height: 6px;
    }
  }
}

.video-progress--filled {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: var(--filmstro-yellow);
  border-radius: 2px;
  transition: height 0.2s ease;
}

.video-options {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 5px;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
  padding: 5px;
  border-radius: 4px;
  align-items: center;
}

.clear-button {
  background: transparent;
  border: none;
  color: white;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: background-color 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  &:active {
    background: rgba(255, 255, 255, 0.2);
  }
}

.vertical-separator {
  width: 1px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
}

.zoom-options {
  display: flex;
  gap: 4px;
  align-items: center;

  button {
    img {
      width: 20px;
      ;
    }
  }
}


.volume-control {
  width: 100px;
  display: flex;
  align-items: center;
  // margin-left: auto;
}

.video-time-wrapper {
  color: white;
  font-size: 12px;
  min-width: 100px;
  text-align: center;
  font-family: monospace;
}

.no-video-wrapper {

  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  // max-height: 290px;
  min-height: 180px;
  text-align: center;
  gap: 20px;
  flex-direction: column;
  // justify-content: space-between;
  cursor: pointer;

  img {
    border-radius: 10px;
    max-height: 60%;
  }

  &:hover {
    border-color: var(--filmstro-yellow);
    background: rgba(255, 255, 255, 0.05);

    img {
      border: dashed 1px var(--filmstro-yellow);
    }
  }

  span {
    color: #9B9B9B;
    font-size: 0.875rem;
    text-align: center;
  }
}

a.video-volume-slider {
  :deep(.range-slider-wrapper) {
    width: 100%;
    height: auto;

    input {
      margin: 0 !important;
      height: 5px;

      &::-webkit-slider-thumb {
        width: 15px !important;
        height: 15px !important;
      }
    }
  }
}

.zoom-input-group {
  position: relative;
  min-width: 100px;
  display: flex;
  align-items: stretch;
  height: 28px;
}

.zoom-select {
  appearance: none;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-right: none;
  border-radius: 4px 0 0 4px;
  color: white;
  cursor: pointer;
  font-size: 12px;
  padding: 4px 8px;
  width: 100%;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }

  &:focus {
    outline: none;
    border-color: var(--filmstro-yellow);

    &+.zoom-icon {
      border-color: var(--filmstro-yellow);
    }
  }

  option {
    background: #2a2a2a;
    color: white;
    padding: 8px;
  }
}

.zoom-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-left: none;
  border-radius: 0 4px 4px 0;
  color: white;
  padding: 0 8px;
  transition: all 0.2s ease;

  :deep(svg) {
    color: rgba(255, 255, 255, 0.8);
  }
}

// Hover effect for the entire input group
.zoom-input-group:hover {
  .zoom-select {
    background: rgba(255, 255, 255, 0.15);
  }

  .zoom-icon {
    background: rgba(255, 255, 255, 0.15);

    :deep(svg) {
      color: white;
    }
  }
}

// Firefox specific styles
@supports (-moz-appearance: none) {
  .zoom-select {
    padding-right: 8px; // Remove extra padding in Firefox
  }
}

// Remove default select arrow in IE
.zoom-select::-ms-expand {
  display: none;
}
</style>