<template>
    <div class="button-group" :class="{ 'grouped': grouped,'flexed':flexed }">
        <button v-for="(button, index) in buttons" :key="index"
            :class="[{ 'first-button': index === 0 }, { 'last-button': index === buttons.length - 1 }, { 'active': value === button.value }]"
            @click="handleButtonClick(button.value)">
            {{ button.label }}
        </button>
    </div>
</template>

<script setup>

const emit = defineEmits(['button-click']);

const props = defineProps({
    buttons: {
        type: Array,
        required: true
    },
    grouped: {
        type: Boolean,
        default: false
    },
    flexed: {
        type: Boolean,
        default: false
    },
    value: {
        default: ''
    }
});



const handleButtonClick = (value) => {
    // const index = props.buttons.findIndex(button => button.value === value);
    // selectedIndex.value = index;
    emit('button-click', value);
};
</script>

<style lang="scss">
.button-group {
    display: flex;
    gap: 10px;


    button {
        padding: 8px 16px;
        background-color: transparent;
        font-size: 12px;
        color: #FFFFFF;
        letter-spacing: -0.4px;
        text-align: center;
        border-radius: 10px;
        border: var(--filmstro-border);
        cursor: pointer;

        &.active {
            background-image: linear-gradient(to right, #FBB402 0%, #FB9102 100%);
            color: #fff;
        }
    }

    &.flexed{
        button{
            flex: 1;
        }
    }
    &.grouped {
        gap: 0px;
        button{
            border-left: solid 1px #ccc;
            border-radius: 0;
        &.first-button {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            // border-left: none;
        }

        &.last-button {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            // border-right: none;
        }
        }
    }

}
</style>
