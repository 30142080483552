export const vBlur = {
  mounted(el) {
    el._blurHandler = () => {
      // Get the currently focused element
      const activeElement = document.activeElement;
      // Only blur if the active element is not the body
      if (activeElement && activeElement !== document.body) {
        activeElement.blur();
      }
    };

    // Add click event listener to the element
    el.addEventListener('click', el._blurHandler);
    // Add mouseleave event listener to the element
    el.addEventListener('mouseleave', el._blurHandler);
  },

  unmounted(el) {
    // Clean up event listeners
    el.removeEventListener('click', el._blurHandler);
    el.removeEventListener('mouseleave', el._blurHandler);
    delete el._blurHandler;
  }
};