<template>
    <label REMOVEv-tippy="`${props.description}`" :for="props.id" class="radio-box">
        {{ label }}
        <input @change="onChange" type="checkbox" :id="props.id" name="ic-r1" :value="props.value">
        <svg class="animated-element" :class="{'press':props.active}" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle class="box-circle" :r="size" cx="15" cy="15" :stroke="props.color" stroke-width="2" fill-opacity=".8"
                :fill="props.color" />
            <!-- <circle class="box-circle" :r="size" cx="15" cy="15" :stroke="props.color" stroke-width="2"
                fill="transparent" /> -->
        </svg>
    </label>
</template>

<script setup>
import { ref } from 'vue';
const emit = defineEmits(['change']);
const active = ref(false)
const props = defineProps({
    id: {
        default: "",
        required: true
    },
    description: {
        default: ""
    },
    label: {
        default: ""
    },
    value: {
        default: 0
    },
    name: {
        default: ""
    },
    size: {
        default: 8,
    },
    active: {
        default: false
    },
    color: {
        default: "white"
    }
})

const onChange = (e) => {
    document.querySelectorAll('.radio-box').forEach(r => { r.classList.remove('active-radio'); })
    e.target.closest('label').classList.add('active-radio');
    emit('change', e.target);
}
</script>

<style lang="scss" scoped>
.radio-box {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-size: .8rem;
    input[type="checkbox"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }
    circle{
        fill-opacity: 0;
        transition: fill-opacity .4s ease;
    }
    .box-circle-active {
        display: none
    }

    &.active-radio {
        circle{
            fill-opacity: .7;
        }
    }

    // display: none;
}</style>