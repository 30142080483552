<template>
    <div class="toggle-container" :class="{ 'toggle-container--vertical': vertical }">
        <span v-if="leftLabel" class="toggle-label toggle-label--left">{{ leftLabel }}</span>
        <label class="toggle-switch" :class="[sizeClass, { 'toggle-switch--vertical': vertical }]">
            <input type="checkbox" v-model="checked" @change="emitChange" />
            <span class="slider" :style="sliderStyle"></span>
        </label>
        <span v-if="rightLabel" class="toggle-label toggle-label--right">{{ rightLabel }}</span>
    </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
const emit = defineEmits();
const props = defineProps({
    modelValue: Boolean,
    size: {
        type: String,
        default: 'medium',
        validator: (value) => ['small', 'medium', 'large'].includes(value)
    },
    leftLabel: {
        type: String,
        default: ''
    },
    rightLabel: {
        type: String,
        default: ''
    },
    color: {
        type: String,
        default: '#4CAF50' // Default green color
    },
    disabledColor: {
        type: String,
        default: '#808080' // Default green color
    },
    vertical: {
        type: Boolean,
        default: false
    }
});

// Local state for the toggle
const checked = ref(props.modelValue);
watch(() => props.modelValue, (newValue) => {
    checked.value = newValue;
});
// Compute size class
const sizeClass = computed(() => `toggle-switch--${props.size}`);

// Compute slider style with dynamic color
const sliderStyle = computed(() => ({
    '--toggle-enabled-color': props.color,
    '--toggle-disabled-color': props.disabledColor
}));

// Emit the change event with the updated value
const emitChange = () => {
    emit('update:modelValue', checked.value);
    emit('changed', checked.value);
    document.activeElement.blur();
};
</script>

<style scoped>
/* Container for toggle and labels */
.toggle-container {
    display: inline-flex;
    align-items: center;
    gap: 8px;
}

/* Vertical container */
.toggle-container--vertical {
    flex-direction: column;
    align-items: center;
}

/* Label styles */
.toggle-label {
    font-size: 14px;
    color: #666;
    user-select: none;
}

/* Base styles for the toggle switch */
.toggle-switch {
    position: relative;
    display: inline-block;
    transition: 0.4s;
}

/* Vertical switch orientation */
.toggle-switch--vertical {
    transform: rotate(90deg);
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--toggle-disabled-color);
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: var(--toggle-enabled-color);
}

/* Size variations */
/* Small size */
.toggle-switch--small {
    width: 40px;
    height: 24px;
}

.toggle-switch--small .slider:before {
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
}

.toggle-switch--small input:checked+.slider:before {
    transform: translateX(16px);
}

.toggle-switch--small~.toggle-label {
    font-size: 12px;
}

/* Medium size (default) */
.toggle-switch--medium {
    width: 60px;
    height: 34px;
}

.toggle-switch--medium .slider:before {
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
}

.toggle-switch--medium input:checked+.slider:before {
    transform: translateX(26px);
}

.toggle-switch--medium~.toggle-label {
    font-size: 14px;
}

/* Large size */
.toggle-switch--large {
    width: 80px;
    height: 44px;
}

.toggle-switch--large .slider:before {
    height: 34px;
    width: 34px;
    left: 5px;
    bottom: 5px;
}

.toggle-switch--large input:checked+.slider:before {
    transform: translateX(36px);
}

.toggle-switch--large~.toggle-label {
    font-size: 16px;
}

/* Vertical label adjustments */
.toggle-container--vertical .toggle-label--left {
    margin-bottom: 4px;
}

.toggle-container--vertical .toggle-label--right {
    margin-top: 4px;
}
</style>