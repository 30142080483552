"use strict";

/**
 * Secret Sauce Object
 * @author Chris
 * @class
 */
export function Wasabi() {

    this.fac = 2 // 0.5

    this.init = async ( config ) => {

        // let configurl = "audio/" + url + "/config.json"
        this.config = await this.getConfig( config )

        // let v = this.calculateVolumes(0.1, 0.2, 0.3)

        // v = this.calculateVolumes(0.3, 0.4, 0.9)
        // console.log(v)

        if ( this.config?.Mixer?.SimpleMode ) this.fac = 1

    }

    this.getConfig = async ( config ) => {
        // let res = await get_api_call(url)
        return config;
    }

    const ctoi = { A: 0, B: 1, C: 2 }

    function deltaC(i, a) {
        const d = Math.abs(i - a);
        return d < 1.0 ? 1.0 - d : 0;
    }

    this.calculateVolumes = (A, B, C) => {
        A = this.fac * A
        B = this.fac * B
        C = this.fac * C
        const vols = [];
        vols.length = this.config.files.length;
        vols.fill(0);
        for (let i in this.config.Patterns) {
            const pat = this.config.Patterns[i];
            const [a, b, c] = [ctoi[i[0]], ctoi[i[1]], ctoi[i[2]]];
            const [ia, ib, ic] = [deltaC(a, A), deltaC(b, B), deltaC(c, C)];
            const influence = ia * ib * ic;
            if (influence != 0) {
                for (let q of pat) {
                    vols[q] += influence;
                }
            }
        }
        return vols;
    }


    // this.init()
}
