import * as Tone from 'tone'
import { Wasabi } from '@/library/modules/Wasabi';
import { MASTERGAIN_VALUE, SAMPLES_PER_SECOND } from '@/constants';
import { convert_zero_two_value_to_zero_one, formatTimeWithFrames, set_video_time } from '@/includes/helpers';
import * as Sentry from "@sentry/vue";

import Algorithm from '@/includes/Algorithm';


const $ = document.querySelector.bind(document);



export class AudioEngine {
    version = "v3.97"
    transport = null // Tone.Transport
    sample_rate = 44100

    debug = true
    Tone = Tone

    buffers = []
    playersA = []
    playersB = []
    stemMutes = []
    songSkipTo = "";
    fileDuration = 0;
    mutedPlayers = {};

    compressorA = null;
    compressorB = null;
    compressorGainA = null;
    compressorGainB = null;
    limiterA = null;
    limiterB = null;
    crossFade = null;
    finalGainA = null;
    finalGainB = null;

    song_starts_sec = 0;
    loop_starts_at_bar = null;
    number_of_bars = 0;
    real_num_of_bars = 0;
    bar_length = 0;
    loop_length_in_bars = 0;
    number_of_loops = 0;
    loop_transition_time = 0.01;
    skip_transition_time = 1;
    force_song_end = 999999; //this needs to be calculate;
    song_fade_ending_time = 0.5; //fadeout in second;
    one_time_skip_from = "";
    one_time_skip_to = "";
    song_resolution = 48;
    music_length_required = null;

    duration = 0;
    realDuration = 0;

    volume = 1;

    timeRestart = null
    timeRestartSafe = null
    shouldLoopNow = false
    shouldSkipNow = false

    loopCounter = 0


    timeCounter = 0
    audition_music = false

    renderPlayer = null

    momentum_slider = null;
    depth_slider = null;
    power_slider = null;
    player_time_progress = null;
    vline = ""
    vlineNum = -1
    transport_started = false;
    drawModeInflection = [false, false, false]
    use_latch_mode = false;
    is_playing = false;
    inflections = [{
        coords0: [],
        nodes:[]
    },
    {
        coords0: [],
        nodes:[]
    },
    {
        coords0: [],
        nodes:[]
    },

    ]
    virtBar = -1
    virtBeat = -1

    offset = 0 //  0.54
    skip_length = 0;




    verbose = true







    toggle = false

    track = false;
    current_time_index = 0;
    use_watermark = false;

    watermarkBuffer = null
    stack = [];
    current_stack = 0;
    algorithm_mode = "best_match";
    saveStack(data) {
        if (!this.stack.length) {
            this.current_stack = 0;
        }
        this.stack[this.current_stack] = JSON.stringify(data)
        this.current_stack += 1
        console.warn(`Saved stack at index = ${this.current_stack}`);
        this.updateSvgPaths();
    }
    checkStack = (title = "") => {
        if (!this.stack.length) {
            this.current_stack = 1;
        }
        if (this.stack[this.current_stack - 1]?.inflections != JSON.stringify(this.inflections)) {

            let data = {
                title:title,
                time:new Date(),
                duration:this.realDuration,
                inflections:this.inflections
            }

            this.saveStack(data)

            // //delte the redo stack if we have undoed
            // if( this.current_stack < this.stack.length ){
            //     this.stack.splice( this.current_stack );
            // }
        } else {
            console.log('Stack the same or empty');
        }
    }
    undo = async () => {

        if (!this.stack.length) {
            console.log('No stack to undo');
            return;
        }
        if(this.stack.length == 1 && this.current_stack == 1 ){
            return;
        }
        this.current_stack -= 1
        if (this.current_stack < 1) {
            this.current_stack = 1
        }
        let data = JSON.parse(this.stack[this.current_stack - 1])
        if (data && data.inflections.length == 3) {
            if( data.duration && data.duration != this.realDuration ){
                console.warn("Duration mismatch", data.duration, this.realDuration)
                // let a = confirm("Duration mismatch, do you want to continue?");
                setTimeout( async () => {
                    await this.calculateValuesFoMusicSeconds( data.duration );
                }, 1000);
            }else{
                this.inflections = data.inflections;

            }
            // this.calculateValuesFoMusicSeconds()
            this.updateSvgPaths();
        }
    }
    redo = () => {

        if (!this.stack.length) {

            return;
        }

        if(this.stack.length == 1 && this.current_stack == 1 ){
            return;
        }

        this.current_stack += 1
        if (this.current_stack >= this.stack.length) {
            this.current_stack = this.stack.length;
        }
        let data = JSON.parse(this.stack[this.current_stack - 1])
        if (data && data.inflections.length == 3) {
            if( data.duration && data.duration != this.realDuration ){
                console.warn("Duration mismatch", data.duration, this.realDuration)
                // let a = confirm("Duration mismatch, do you want to continue?");
                setTimeout( async () => {
                    await this.calculateValuesFoMusicSeconds( data.duration );
                }, 1000);
            }else{
                this.inflections = data.inflections;

            }
            // this.calculateValuesFoMusicSeconds()
            // this.inflections = data.inflections;
            this.updateSvgPaths();
        }
    }
    get stackArray() {
        return this.stack.map(s => JSON.parse(s));
    }
    get currentStackArray() {
        let stk = Math.max(0, this.current_stack - 1);
        return this.stack[stk] ? JSON.parse(this.stack[stk]) : null;
    }
    constructor() {
        this.listeners = new Map();
    }


    get selected_inflection_index() {
        return Number(document.body.getAttribute('data-slider-index-selected'));
    }
    get selected_inflection_name() {
        return document.body.getAttribute('data-slider-selected');
    }
    get selected_inflections() {

        let idx = this.selected_inflection_index;
        if (idx >= 0) {
            return this.inflections[idx];
        } else {
            return this.inflections[0];
        }
        return []
    }
    get current_time() {
        if (this.transport) {
            return Number(Tone.Time(this.transport.position).toSeconds());
        }
        return 0;
    }
    get normalized_current_time(){
        if( this.transport ){
            return Number( Math.max(0, Number(Tone.Time(this.transport.position).toSeconds() - this.offset)).toFixed(3) );
        }
        return 0;
    }

    dispatchEvent = (name, data = null) => {
        if( data && data.hasOwnProperty( 'engine' ) ){
            data.engine = this;
        }
        document.dispatchEvent(new CustomEvent(name, {
            bubbles: false,
            detail: data
        }))

    }

    on = (name, callback = () => { }) => {
        document.addEventListener(name, callback);
    }
    off = (name, callback = () => { }) => {
        document.removeEventListener(name, callback);
    }

    new_on(eventName, callback) {
        if (!this.listeners.has(eventName)) {
            this.listeners.set(eventName, new Set());

            // Add the actual DOM event listener only once
            document.addEventListener(eventName, (e) => {
                if( this.listeners.size>0 ){
                // Notify all callbacks when the event occurs
                    this.listeners.get(eventName)?.forEach(cb => cb(e));
                }
            });
        }

        // Add to our set of callbacks
        this.listeners.get(eventName)?.add(callback);
    }
    new_off(eventName, callback) {

        if (this.listeners.has(eventName)) {
            if (callback) {
                this.listeners.get(eventName)?.delete(callback);
            } else {
                this.listeners.delete(eventName);
            }
            document.removeEventListener(eventName,callback);
        }

    }
    loadBuffers = async () => {
        console.log("loadBuffers");
        let $this = this;
        let listOfPromises = []

        // this.splayer.TonePlayers.dispose();
        // this.buffers.forEach(el => {
        //     console.log(el)
        //     el.dispose()
        // });
        this.buffers.length = 0
        this.buffers = []
        this.playersA.forEach(el => {
            el.dispose()
        });
        this.playersB.forEach(el => {
            el.dispose()
        });




        this.dispatchEvent('loading-audio-files', { files: this.track.files });

        for (let i = 0; i < this.track.files.length; i++) {
            let el = this.track.files[i];
            // el = el.split("?")[0].split("/").slice(-1)[0]
            // console.log(el, path)
            let filename = el;
            filename = filename.replace(".wav", ".mp3");
            listOfPromises.push(
                Tone.ToneAudioBuffer.load(filename).then((b) => {
                    // await new Tone.ToneAudioBuffer(filename, (b) => {
                    // console.log(222, b, i)
                    this.buffers[i] = b
                    $("#htitle").innerText = `Loading ${i} of ${this.track.files.length}`
                    this.fileDuration = b.duration;
                })
            )
            // await this.wait(Math.random() * 100)
        }

        await Promise.all(listOfPromises).then(() => {
            console.log("all sounds loaded")
            // this.prepareTransport()
            this.watermarkPlayer = new Tone.Player(new Tone.ToneAudioBuffer("/audio/watermark_short.mp3"))
            this.watermarkPlayer.toDestination();
            this.dispatchEvent('audio-loaded', this.track.files)
        });

    }

    // MASTERING FUNCTIONS (similar, but not identical to the functions in WebTheme.js)
    // TO DO: Move the range checking functions into a shared file at a later date
    setupCompressor(defaultThreshold = -34, defaultKnee = 40, defaultRatio = 1.1, defaultAttack = 0.101, defaultRelease = 0.01) {
        let compressor = new Tone.Compressor();

        // Set the compressor settings from the track (CMS) or use defaults
        let threshold = this.checkCompressorThresholdIsInRange(Number(this.track.Effects?.threshold)) ? Number(this.track.Effects.threshold) : defaultThreshold;
        let knee = this.checkCompressorKneeIsInRange(Number(this.track.Effects?.knee)) ? Number(this.track.Effects.knee) : defaultKnee;
        let ratio = this.checkCompressorRatioIsInRange(Number(this.track.Effects?.ratio)) ? Number(this.track.Effects.ratio) : defaultRatio;
        let attack = this.checkCompressorAttackOrReleaseIsInRange(Number(this.track.Effects?.dattracktime)) ? Number(this.track.Effects.dattracktime) : defaultAttack;
        let release = this.checkCompressorAttackOrReleaseIsInRange(Number(this.track.Effects?.releasetime)) ? Number(this.track.Effects.releasetime) : defaultRelease;

        compressor.threshold.value = threshold;
        compressor.knee.value = knee;
        compressor.ratio.value = ratio;
        compressor.attack.value = attack;
        compressor.release.value = release;

        // Debug data
        $("#compthreshold").innerText = threshold;
        $("#compknee").innerText = knee;
        $("#compratio").innerText = ratio;
        $("#compattack").innerText = attack;
        $("#comprelease").innerText = release;

        return compressor;
    }
    setupCompressorGain() {
        let compressorGain = new Tone.Gain(0, "decibels");

        // Set the compressor gain settings from the track (CMS) or use defaults
        let gain = this.track.Effects?.mastergain ? this.track.Effects.mastergain : 0;

        compressorGain.gain.value = gain;

        // Debug data
        $("#compgain").innerText = gain + "dB";

        return compressorGain;
    }
    setupLimiter(defaultThreshold = -0.2, defaultKnee = 10, defaultRatio = 20, defaultAttack = 0.003, defaultRelease = 0.01) {
        // Note - the tonejs limiter has limited controls and is a compressor under the hood, so we're using a compressor here to get more access to it's controls
        // The defaults are the settings that the limiter uses (via a compressor)
        let limiter = new Tone.Compressor();

        // Set the compressor settings from the track (CMS) or use defaults
        let attack = this.checkCompressorAttackOrReleaseIsInRange(Number(this.track.Effects?.lattracktime)) ? Number(this.track.Effects.lattracktime) : defaultAttack;
        let release = this.checkCompressorAttackOrReleaseIsInRange(Number(this.track.Effects?.decaytime)) ? Number(this.track.Effects.decaytime) : defaultRelease;

        limiter.threshold.value = defaultThreshold;
        limiter.knee.value = defaultKnee;
        limiter.ratio.value = defaultRatio;
        limiter.attack.value = attack;
        limiter.release.value = release;

        // Debug data
        $("#limthreshold").innerText = defaultThreshold;
        $("#limknee").innerText = defaultKnee;
        $("#limratio").innerText = defaultRatio;
        $("#limattack").innerText = attack;
        $("#limrelease").innerText = release;

        return limiter;
    }
    checkCompressorThresholdIsInRange(x) {
        // Tonejs compressor threshold range is -100 (min) to 0 (max)
        return x >= -100 && x <= 0;
    }
    checkCompressorKneeIsInRange(x) {
        // Tonejs compressor knee range is 0 (min) to 40 (max) (this is a dB value, lower is harder, higher is softer)
        return x >= 0 && x <= 40;
    }
    checkCompressorRatioIsInRange(x) {
        // Tonejs compressor ratio range is 1 (min) to 20 (max)
        return x >= 1 && x <= 20;
    }
    checkCompressorAttackOrReleaseIsInRange(x) {
        // Tonejs compressor attack and relase range is 0 (min) to 1 (max)
        return x >= 0 && x <= 1;
    }



    prepareTransport = async () => {



        this.playersA = []
        this.playersB = []

        clearInterval(this.meterInterval)

        this.compressorA = this.setupCompressor();
        this.compressorB = this.setupCompressor();
        this.compressorGainA = this.setupCompressorGain();
        this.compressorGainB = this.setupCompressorGain();
        this.limiterA = this.setupLimiter();
        this.limiterB = this.setupLimiter();

        this.crossFade = new Tone.CrossFade();
        // Set crossfade fully to A
        this.crossFade.fade.value = 0;

        this.finalGainA = new Tone.Gain().toDestination();
        this.finalGainB = new Tone.Gain().toDestination();

        for (let i = 0; i < this.track.files.length; i++) {
            let el = this.track.files[i]

            let file = el;
            file = file.replace(".wav", ".mp3");

            this.playersA[i] = new Tone.Player(this.buffers[i])
            this.playersA[i].filename = file

            this.playersA[i].wasabiGain = new Tone.Gain()
            this.playersA[i].wasabiGain.gain.value = 0.005
            this.playersA[i].meter = new Tone.Meter()
            this.playersA[i].connect( this.playersA[i].meter )
            this.playersA[i].chain(this.playersA[i].wasabiGain, this.compressorA, this.compressorGainA, this.limiterA, this.crossFade.a, this.finalGainA)
            this.playersA[i].sync().start()

            this.playersB[i] = new Tone.Player(this.buffers[i])
            this.playersB[i].wasabiGain = new Tone.Gain()
            this.playersB[i].wasabiGain.gain.value = 0.005
            this.playersB[i].meter = new Tone.Meter()
            this.playersB[i].connect(this.playersB[i].meter)
            this.playersB[i].chain(this.playersB[i].wasabiGain, this.compressorB, this.compressorGainB, this.limiterB, this.crossFade.b, this.finalGainB)
            this.playersB[i].sync().start()
        }

        this.playersA.map((el, i) => { el.mute = this.mutedPlayers[i] })
        this.playersB.map((el, i) => { el.mute = this.mutedPlayers[i] })


        // console.log("playersA length", this.playersA.length)


        // Promise.all(listOfPromises).then(() => {

        // console.log(this.playersA)
        // $("#b-start").disabled = false
        // $("#b-start").innerText = "start"

        $("#htitle").innerText = `Track:  ${this.track.name}`;

        this.dispatchEvent('music-players', { players: this.playersA, engine: this })

        this.meterInterval = setInterval(() => {
            if (this.transport && this.transport.state !== 'started') {
                return;
            }
            this.calculateValues('meteritinterval')
            $("#log4").innerText = "duration: " + this.realDuration

            if (this.toggle) {
                this.playersA.forEach((el, i) => {
                    let v = Tone.dbToGain(this.playersA[i].meter.getValue())
                    if ($("#stem-meter-" + i)) {
                        $("#stem-meter-" + i).value = (v * 10) * this.playersA[i].wasabiGain.gain.value
                    }
                });

            } else {
                this.playersB.forEach((el, i) => {
                    let v = Tone.dbToGain(this.playersB[i].meter.getValue())
                    if ($("#stem-meter-" + i)) {
                        $("#stem-meter-" + i).value = (v * 10) * this.playersB[i].wasabiGain.gain.value
                    }
                });
            }

            let playerAfade = 1 - this.crossFade.fade.value;
            $("#players").innerText = `A: ${playerAfade.toFixed(2)} B: ${this.crossFade.fade.value.toFixed(2)}`
        }, 50);

        // });


    }
    setCurrentPlayTime = (time) => {
        if (time < 0) {
            time = 0;
        }
        // const formatted_time = `${new Date(time * 1000).toISOString().substring(14, 19)}`
        if (this.player_time_progress) {
            // this.player_time_progress.innerHTML = formatted_time;
        }
        const formatted_time = formatTimeWithFrames( time );

        this.dispatchEvent('set-current-time', { time: formatted_time });
    }
    setPlayHeadPosition(time = false) {

        // this.setPlayheadTime(time);
        // return;
        let svg = document.querySelector('.timeline');
        if (time == false) {
            time = Tone.Time(this.transport.position).toSeconds();
        }
        // time -= this.offset;

        if (svg) {
            let time_percent = (time / this.realDuration) * 100;
            const rect = svg.getBoundingClientRect();
            const svgPoint = svg.createSVGPoint();
            svgPoint.x = (time_percent / 100) * rect.width;
            svgPoint.y = 0;
            let a = svg.getScreenCTM();
            if (a) {
                try {
                    const transformedPoint = svgPoint.matrixTransform(a.inverse());
                    let pos = transformedPoint.x + rect.x;
                    // pos = Math.max(0, pos);
                    this.dispatchEvent('set-playhead-position', { position: pos, time, offs: time + this.offset });
                } catch (e) {
                    console.error(e)
                    Sentry.captureException(e);

                }
            }
        }

        let a = document.querySelector('#secs');

        if (a) {
            a.innerHTML = `${Math.max(0, time).toFixed(1)}s`;
        }

        // this.playhead_position = position.x;
    }



    doSchedule = (transport) => {


        this.timeRestart = this.loop_starts_at_bar + "m"


        // this.one_time_skip_to = this.songSkipTo

        this.evt2 = transport.scheduleRepeat((time) => {
            // just for logging purposes
            // could possibly be a setInterval
            let [bar, beat, six] = transport.position.split(":");
            // console.log("-----")
            // console.log(transport.position)
            // console.log(bar, beat, six)
            bar = parseInt(bar)
            beat = parseInt(beat)
            six = parseFloat(six)
            // console.log(bar, beat, six)
            const t = Tone.Time(transport.position).toSeconds() - this.offset;

            this.realBarBeat = bar + ":" + beat
            this.virtBarBeat = this.virtBar + ":" + this.virtBeat // check_4
            this.virtBarBeatSix = this.virtBar + ":" + this.virtBeat + ":" + six
            // console.log(this.virtBarBeatSix)


            this.setCurrentPlayTime(t);

            $("#log3").innerText = "bar/beat " + this.realBarBeat + " vbar/vbeat " + this.virtBarBeat + " - " + t.toFixed(1) + "s loop:" + (this.loopCounter)

            this.setPlayHeadPosition(t)

            this.dispatchEvent('music-progress', { time: t, engine: this })

            //pause on range end
            // if( window.start_range >=0 && window.end_range ){
            //     // this.pause();
            //     console.log( `Time :${t}`, window.start_range );
            //     if( t * 10 >=window.end_range ){
            //         this.pause()
            //     }
            // }

        }, "24i");


        this.helperBar = 0
        this.aaaBar = 0
        this.evt1 = transport.scheduleRepeat(async (time) => {
            // main loop (1), happening every beat

            const [bar, beat] = transport.position.split(":").map(x => parseInt(x));

            const signature = this.track.timeSignature[0]

            this.virtBeat += 1
            if (!this.isRendering) this.virtBeat = beat

            this.virtBeat = this.virtBeat % this.track.timeSignature[0]
            // }, 10);
            if (this.virtBeat == 0) this.helperBar += 1

            if (!this.isRendering) {
                // this.virtBar = bar
                this.helperBar = bar
            }

            // force initial sync
            if (bar == 1 && beat == 0) {
                this.helperBar = 1
                this.virtBeat = 0
            }

            let t = Tone.Time(transport.position).toSeconds()
            t = parseInt(t * 10) / 10
            t = t.toFixed(1)
            // console.log(this.helperBar)
            // let c = (this.helperBar - this.loop_starts_at_bar - (this.loopCounter) * this.loop_length_in_bars)
            let c = this.helperBar - this.loop_starts_at_bar - (this.number_of_loops - this.loopCounter + 1) * this.loop_length_in_bars
            // console.log(this.helperBar, this.loopCounter, c)  // check_5
            // if (!this.isRendering) console.log("--- evt1", toggle, c, this.loop_length_in_bars, this.loopCounter);

            let str = "--- evt1: "
            str += t + "s c:"
            str += c + " hb:"
            str += this.helperBar + " "
            str += this.loopCounter + " "
            str += this.realBarBeat + " " + this.virtBarBeat
            // if (!this.isRendering) console.log(str);

            this.shouldLoopNow = false

            // if (this.number_of_loops > 0 && c == 0 && (this.virtBeat) == 0 && this.loopCounter <= this.number_of_loops) {
            if (c == 0 && (this.virtBeat) == 0 && this.loopCounter > 0) {
                if (!this.isRendering) console.log("LOOP NOW!!!", this.helperBar, c)
                this.shouldLoopNow = true
                this.vline -= this.loop_length_in_bars

            }


            if (this.virtBeat == 0) {
                if (this.shouldLoopNow) {
                    this.virtBar = this.loop_starts_at_bar
                    // console.log("happens!!")
                } else {
                    this.virtBar += 1
                    if (!this.isRendering && this.virtBar == (bar - 1)) {
                        console.log("correction")
                        this.virtBar = bar
                    }
                }
            }


            if (this.one_time_skip_from != "") {

                let condition = true // check_5
                condition = condition && this.virtBar + ":" + this.virtBeat == this.one_time_skip_from
                // if (this.number_of_loops > 0) condition = condition && (this.number_of_loops - this.loopCounter) < 0
                if (this.number_of_loops > 0) condition = condition && this.loopCounter < 1
                // condition = condition && this.loopCounter < 1

                if (condition) { // check_9
                    // if (bar + ":" + beat == this.one_time_skip_from) {
                    // if (beat == 0 && bar + "m" == this.one_time_skip_from) {
                    if (!this.isRendering) console.log("SKIP NOW!!!", `${this.virtBar}:${this.virtBeat}`, c) // check_4
                    $("#log1").innerText = "SKIPPING to " + this.one_time_skip_to
                    // this.loopCounter += 1
                    this.shouldSkipNow = true
                }
            }



            if (this.shouldSkipNow) {
                const [bar, beat] = this.one_time_skip_to.split(":").map(x => parseInt(x))
                // await this.pause()
                // debugger
                this.virtBar = bar
                this.virtBeat = beat

            }


            this.doLoop(time)
            this.doSkip(time)

        }, "4n", 0);


        this.evt0 = transport.scheduleRepeat((time) => {
            // main loop (2) resolution, setting the wasabi
            this.timeCounter += 1

            this.setWasabi()

        }, `${this.song_resolution}i`, 0);


        //we need to update the svgs ever 0.1 seconds
        // 1 second = 10 "points"
        let points_interval_value = 1 / 10;
        this.time_counter_repeat = transport.scheduleRepeat((time) => {
            this.current_time_index++;
            if (this.isDrawing()) {
                let indx = this.drawModeInflection.findIndex(a => a == true)
                this.updateSvgPaths(indx >= 0 ? indx : false);
            }
        }, points_interval_value, this.current_time_index);


        this.evt13 = transport.scheduleRepeat((time) => {
            // secondary loop, counting the virtual timeline
            // const [bar, beat] = transport.position.split(":").map(x => parseInt(x));

            let _time = Tone.Time(transport.position).toSeconds();

            let _volume = this.xlerp(_time, this.force_song_end + this.offset, this.song_fade_ending_time)

            this.setMasterVolumeSlider(_volume)

            // make sure the whole track stops
            if (_time > this.force_song_end + this.offset) {
                // transport.stop(time)
                this.stopPlay()
                $("#log1").innerText = "forcestopped at " + this.force_song_end
            }

            if (_time >= (this.realDuration + this.offset)) {
                this.stopPlay();
                $("#log1").innerText = "Finished playing after " + _time
            }


        }, "12i", 0);

        if (this.use_watermark) {
            this.watermark_schedule = transport.scheduleRepeat(
                (time) => {
                    this.watermarkPlayer.start(time);
                },
                "15s"
            );
        }



    }

    lerp = (x, y, a) => {
        if (a > 0 && a < 1) $("#log1").innerText = "forcing end at " + this.force_song_end
        if (a <= 0) return x
        if (a >= 1) return y
        return x * (1 - a) + y * a;
    }

    xlerp = (t, s, n) => {
        let l = this.lerp(0, 1, (s - t) / n)
        return l
    }


    doLoop = (time) => {

        if (!this.shouldLoopNow) {
            return;
        }

        this.toggle = !this.toggle

        if (this.toggle) {
            console.log("playersA running") // + this.timeRestartSafe)
            console.log("RESTART", this.timeRestart, this.timeRestartSafe)

            this.playersA.forEach((el, i) => {
                // el.restart(Tone.now(), this.timeRestart, this.timeRestartSafe)
                el.restart(time, this.timeRestart)
                // console.log(333, i)
            });

            // Going from B to A
            this.crossFade.fade.linearRampTo(0, this.loop_transition_time, time);
        } else {
            // console.error("playersB running")
            console.log("RESTART", this.timeRestart, this.timeRestartSafe)

            this.playersB.forEach((el, i) => {
                // el.restart(Tone.now(), this.timeRestart, this.timeRestartSafe)
                el.restart(time, this.timeRestart)
                // console.log(444, i)
            });

            // Going from A to B
            this.crossFade.fade.linearRampTo(1, this.loop_transition_time, time);
        }
        this.loopCounter -= 1
    }

    doSkip = (time) => {

        if (!this.shouldSkipNow) {
            return;
        }

        this.shouldSkipNow = false // we only do this once

        this.toggle = !this.toggle
        if (this.toggle) {
            console.log("playersA running") // + this.timeRestartSafe)
            console.log("SKIP A", this.one_time_skip_to, this.timeRestartSafe)

            this.playersA.forEach((el, i) => {
                el.restart(time, this.one_time_skip_to)
            });

            // Going from B to A
            this.crossFade.fade.linearRampTo(0, this.skip_transition_time, time);
        } else {
            // console.log("playersB running")
            console.log("SKIP B", this.one_time_skip_to, this.timeRestartSafe)

            this.playersB.forEach((el, i) => {
                el.restart(time, this.one_time_skip_to)
            });

            // Going from A to B
            this.crossFade.fade.linearRampTo(1, this.skip_transition_time, time);
        }
        // this.toggle = !this.toggle
        // this.loopCounter += 1
    }

    wait = async (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    changeVolume = (i) => { // check_1
        let v = parseFloat($("#vol_" + i).value)
        console.log(i, v)
        this.playersA[i].wasabiGain.gain.value = v
        this.playersB[i].wasabiGain.gain.value = v
    }
    mutePLayer(index, val = true) {
        this.playersA[index].mute = val;
        this.playersB[index].mute = val;
        this.mutedPlayers[index] = val;
    }
    toggleSoloStem(stem_index, set_solo = false) {
        try {
            if (set_solo) {
                this.playersA.forEach((pl, index) => pl.mute = index === stem_index ? false : true)
                this.playersB.forEach((pl, index) => pl.mute = index === stem_index ? false : true)
            } else {
                this.playersA.forEach((pl, index) => pl.mute = false)
                this.playersB.forEach((pl, index) => pl.mute = false)

                Object.keys(this.mutedPlayers).forEach(muted_stem => {
                    if (this.mutedPlayers[muted_stem]) {
                        this.mutePLayer(muted_stem);
                    }
                });

            }
        } catch (e) {
            Sentry.captureException(e);
        }
    }

    doPar = (that, i, j) => {
        console.log(that.value, i)
        if (i == 2) {
            debugger
            let v = that.checked
            console.log(v)
            this.playersB[j].mute = that.checked
            this.playersA[j].mute = that.checked
        }
        if (i == 13) {
            this.audition_music = that.checked
        }
    }


    setWasabi = () => { // check_1

        if (this.audition_music) {
            console.log('IS AUDITIONING...just use the values of the input....');
        } else {

            this.inflections.forEach((inf, i) => {

                let slider_value = parseFloat($("#daw-" + i).value);

                if (this.drawModeInflection[i]) {
                    this.setCoordValue(i, this.current_time_index + 2, slider_value);
                    this.setCoordValue(i, this.current_time_index + 1, slider_value);
                    this.dispatchEvent('using-draw-mode', { index: i, value: slider_value, time: this.normalized_current_time });

                }
                else {
                    let v = this.getCoordValue(i, this.current_time_index);
                    if (v >= 0) {
                        this.setSliderValue(i, v)
                    }
                }
            })

        }


        //also set the plauhead position here ?


        // if (this.config.Mixer.SimpleMode) this.fac = 0.5
        // let fac = 1 // this.fac * 2 // SimpleMode

        let volumes = this.wasabi.calculateVolumes(this.momentum_slider.value, this.depth_slider.value, this.power_slider.value)

        for (let i = 0; i < this.track.files.length; i++) {
            this.playersA[i].wasabiGain.gain.value = volumes[i];
            this.playersB[i].wasabiGain.gain.value = volumes[i];

            //set the stem volume ?
            // $("#vol_" + i).value = volumes[i] // check_1

            // this.playersA[i].wasabiGain.gain.rampTo(r[i], 0.1)
            // this.playersB[i].wasabiGain.gain.rampTo(r[i], 0.1)
        }
    }

    setMasterVolumeSlider = (v, slider = true) => {
        let max_vol = this.isRendering ? 1 : this.volume;
        let mx = Math.max(0, Math.min(max_vol, v));

        this.finalGainA.gain.value = Number(mx);//Number(mx * this.master_gain);
        this.finalGainB.gain.value = Number(mx);//Number(mx * this.master_gain);
        if (slider) {
            $("#master-volume").value = mx;
        }
    }

    setMasterGain(v) {
        this.compressorGainA.gain.value = v;
        this.compressorGainB.gain.value = v;
    }

    init = async (config) => {

        this.momentum_slider = document.getElementById('daw-0');
        this.depth_slider = document.getElementById('daw-1');
        this.power_slider = document.getElementById('daw-2');
        this.player_time_progress = document.querySelector("#current-play-time")


        console.log("init")


        this.track = config;

        Tone.context.dispose()
        Tone.setContext(new Tone.Context())
        Tone.start()


        this.wasabi = new Wasabi()
        this.wasabi.init(this.track);

        await this.loadBuffers()

        await this.init2()

        //add this.rack.v3_master_gain
        if (this.track.v3_master_gain && this.track.v3_master_gain > 0) {
            // this.master_gain = Number( this.track.v3_master_gain )
        }

        // if( this.track.loop_start_bar && this.track.loop_start_bar !=='') {
        //     $("#loop-start-bar").value = Number( this.track.loop_start_bar );
        // }


        this.setMasterVolumeSlider(1)
        this.calculateValues('init');

        return this;
    }


    init2 = async () => {






        // setTimeout(() => {


        $("#log").innerText = ""
        $("#log1").innerText = " "
        $("#log3").innerText = "not started"


        // $("#b-start").disabled = true
        // $("#b-start").innerHTML = "&nbsp;"

        this.transport = Tone.getTransport()
        this.destination = Tone.getDestination()


        // this.transport = Tone.Transport
        this.transport.bpm.value = this.track.bpm
        this.transport.timeSignature = this.track.timeSignature

        // await this.loadBuffers()
        // this.doSchedule()



        this.prepareTransport()

        this.setMasterVolumeSlider(1)

        this.setWasabi();
    }


    readInputValues = () => {

        this.song_starts_sec = parseInt($("#song-start-bar").value)
        this.loop_starts_at_bar = parseInt($("#loop-start-bar").value)
        this.number_of_loops = parseInt($("#loop-number").value)
        this.loop_length_in_bars = parseInt($("#loop-length").value)
        this.skip_transition_time = parseFloat($("#skip-transition-time").value)

        this.force_song_end = parseFloat($("#song-end").value)
        this.song_fade_ending_time = parseFloat($("#song-end-time").value)

        this.one_time_skip_from = $("#song-skip-from").value
        this.one_time_skip_to = $("#song-skip-to").value
        this.song_resolution = parseFloat($("#song-resolution").value)
        this.sample_rate = parseFloat($("#song-sample-rate").value)

    }



    startPlay = async () => {
        await this.calculateValues('startplay');
        if (window.selection_range && window.selection_range.startTime) {
            this.setTransportPosition(  window.selection_range.startTime )
            set_video_time( window.selection_range.startTime )
        }

        this.transport.start();
        if (this.paused_seconds) {
            await setTimeout(() => {
                this.setPlayheadTime(this.paused_seconds)
                this.paused_seconds = null;
            }, 50);
        }


        this.setPlayStatus(true);
        if( this.use_latch_mode ){
            // window.latchmode_selection = true;
        }
        this.dispatchEvent('audioengine-play', { engine: this });


    }
    setPlayStatus(val) {
        this.is_playing = val;
        this.dispatchEvent('play-status', { playing: this.is_playing })
    }
    pause = async () => {
        //pause offset by 0.5 seconds
        this.paused_seconds = this.current_time - this.offset;
        this.transport.pause();
        this.setPlayStatus(false);
        window.latchmode_selection = false
    }

    stopPlay = () => {
        this.stopTransport();
        this.setPlayHeadPosition(0 - this.offset);
        this.setPlayStatus(false);
        // document.querySelector('#timeline-playhead').scrollIntoView();
        this.dispatchEvent('stop-music');
        this.setPlayheadTime( 0 )
        this.setCurrentPlayTime( 0 );

    }
    onSliderMouseUp = (index) => {
        this.drawModeInflection[index] = true;
        this.dispatchEvent(`slider-mouse-up`, { index: index, engine: this });
    }
    isDrawing() {
        return this.drawModeInflection.find(d => d == true);
    }
    setAllInflectionValues(value) {
        this.inflections.forEach((inf, inf_i) => {
            for (let i = 0; i < inf.coords0.length - 1; i++) {
                this.setCoordValue(inf_i, i, value);
            }

        })
        this.updateSvgPaths();
    }

    setRandomInflections(index = false, range = 30) {

        let data = [];
        if (index == false) {
            this.inflections.forEach((inf, inf_i) => {
                let v = 0.5;
                for (let i = 0; i < inf.coords0.length - 1; i++) {
                    if (i % range == 0) {
                        v = Number(Math.random().toFixed(3))
                    }
                    this.setCoordValue(inf_i, i, v);
                }

            })
        } else {
            index = Math.min(Math.max(index, 0), 2)
            let v = 0.5;
            for (let i = 0; i < this.inflections[index].coords0.length - 1; i++) {
                if (i % range == 0) {
                    v = Number(Math.random().toFixed(3))
                }
                this.setCoordValue(index, i, v);
            }
        }

        this.updateSvgPaths();
    }

    setInflection = (index, value) => {
        value = Number(value);
        // this.drawModeInflection[index] = true;
        if (typeof window.selection_range !== 'undefined' && window.selection_range !== null && !this.is_playing) {
            this.setInflectionRangeValues(index, window.selection_range.startTime * 10, window.selection_range.endTime * 10, value);
            return;
        }

        if (!this.audition_music) {


            if (this.use_latch_mode) {
                if( this.is_playing ){
                    window.latchmode_selection = true
                }
                // we don't want to use latchmode when we have made progress in the song, so we check if we are at the start only
                if( this.current_time_index == 0 || this.is_playing){
                    for (let i = this.current_time_index; i < this.inflections[index].coords0.length ; i++) {
                        this.setCoordValue(index, i, value);
                    }
                    let start  = Number((this.current_time - this.offset).toFixed( 3 ));
                    let end  = Number( this.realDuration );
                    this.dispatchEvent('set-inflections-with-latch-mode',{value,start ,end });
                    // this.dispatchEvent('set-inflections-with-latch-mode',{value,start:this.current_time,end:this.realDuration });
                }else{
                    this.dispatchEvent('disable-latch-mode');
                }
            }else{
                window.latchmode_selection = false
            }

            if (this.is_playing) {
                this.setCoordValue(index, this.current_time_index, value);
                this.setCoordValue(index, this.current_time_index + 1, value);
            } else {
                this.updateSvgPaths(index);
            }

        }

    }



    // ================================
    offlineRender = async () => {
        console.log("offlineRender")

        this.stopPlay()
        await this.offlineRenderStart()
        // this.init2()
    }
    offlineRenderStart = async () => {

        console.log("offlineRenderStart") // check_2
        this.dispatchEvent('render-started', `Rendering...`);
        // this.dispatchEvent('loading-message', `Rendering...`);
        // Tone.context.dispose()
        // Tone.setContext(new AudioContext())
        // Tone.start()

        // console.log(Tone.context.state)
        // await this.init2()

        Tone.start()
        // await this.wait(500)
        console.log(Tone.context.state)

        if (Tone.context.state !== 'running') {
            console.error("Tone context state not running");
        }

        // if (Tone.context.state !== 'running') {
        //     Tone.context.resume();
        //     Tone.start()
        // }
        let time_start = +new Date();


        const renderingPromise = Tone.Offline(({ transport }) => {
            this.isRendering = true;

            setTimeout(() => {

            }, 1000);

            this.init2()

            Tone.getContext()._context.lookAhead = 0
            // Tone.getContext()._context.latencyHint = "playback"
            this.song_starts_sec = 0
            this.startTransport(true)
            // }, 10)
        }, this.realDuration)

        await renderingPromise.then(buffer => {
            this.isRendering = false

            let time_end = +new Date();
            let time_result = time_end - time_start;

            this.stopTransport()

            this.buffer = buffer

            console.log("render done " + time_result)

            this.dispatchEvent('loading-message', `Finalizing rendered file...`);
            this.init2();
        });

        this.createDownload(this.buffer)

        this.renderPlayer = new Tone.Player(this.buffer).toDestination()
        return this.buffer;

    }



    createDownload = (buffer) => {
        this.download(buffer)
    }

    createTestDownload = () => {
        // this.download(buffer)
        let buffer = this.testBuffer
        this.createDownload(buffer)
    }

    download = (buffer) => {

        let sampleRate = this.sample_rate;

        let offlineAudioCtx = new OfflineAudioContext({
            numberOfChannels: 2,
            length: sampleRate * buffer.duration,
            sampleRate: sampleRate,
        });

        // var song = offlineAudioCtx.createBufferSource();
        this.make_download(buffer, offlineAudioCtx.length);
    }


    // ================================

    doSelect = (t) => {
        console.log(t.value)

        this.stopTransport()

        this.num = t.value
        this.track = config.tracks[this.num]
        // this.init()

    }

    updateSvgPaths(index = false) {
        let size = 200;
        this.dispatchEvent('update-svg-path', { inflections: this.inflections })

        if (index !== false) {
            this.dispatchEvent(`update-svg-path-${index}`, { inflections: this.inflections[index] });
            return;
        }


        this.inflections.forEach((d, indx) => {
            this.dispatchEvent(`update-svg-path-${indx}`, { inflections: this.inflections[indx] });
        })

    }
    setSliderValue(index, value) {
        if (typeof value == "undefined") {
            return
        }
        if (!$(`#daw-${index}`)) { return }
        $(`#daw-${index}`).value = value;
        $(`#daw-${index}-p`).value = value
        // $(`#daw-${index}`-p).setAttribute('data-y',value );//
    }
    setCoordValue(inflection, index, value) {
        this.inflections[inflection].coords0[index] = value;
    }

    getCoordValue(inflection, index) {
        return this.inflections[inflection].coords0[index];
    }

    maybeGetInitialInflectionsForInflection(inf) {
        let val = 0.5;

        if (this.track && this.track.inflections) {
            try {
                if (inf == 0) {
                    val = convert_zero_two_value_to_zero_one(Number(this.track.inflections['momentum']));
                }
                else if (inf == 1) {
                    val = convert_zero_two_value_to_zero_one(Number(this.track.inflections['depth']));
                }
                else if (inf == 2) {
                    val = convert_zero_two_value_to_zero_one(Number(this.track.inflections['power']));
                }
            } catch (e) {
                Sentry.captureException(e);
            }
        }
        return val;
    }
    createDatapoints = (inflections = false) => {

        let infs = [];
        // let infs =[];
        let total_points = parseInt(Number(this.realDuration * 10));
        if (total_points < this.music_length_required) {
            console.error('WTF?', total_points, this.music_length_required);
        }
        if (total_points <= 0) return;
        for (let inf = 0; inf < this.inflections.length; inf++) {
            let i = 0;

            let inx = Math.max(0, this.inflections[inf].coords0.length - 1)

            if (total_points > inx) {
                //we don't want to reset everything we just want to start from the last point.
                i = inx;

            } else if (total_points < inx) {
                //we currently have more values in our array from what we need.
                //most likely we have set a custom lenght shorter than the previous.
                this.inflections[inf].coords0.splice(total_points, inx)
                continue;
            }


            for (i; i < total_points ; i++) {
                // let val = Math.random().toFixed(2);
                let val = 0.5;
                if( inx > 0 ){
                    val = this.inflections[inf].coords0[ inx ]
                }else{
                    val = this.maybeGetInitialInflectionsForInflection( inf );
                }

                this.inflections[inf].coords0.push(val);
            }

            this.dispatchEvent(`created-coords-${inf}`, { engine: this, inflections: this.inflections[inf].coords0 });

        }

        this.dispatchEvent('created-coords', { engine: this, inflections: this.inflections });
        this.inflections.forEach((inf, inf_i) => {
            this.setSliderValue(inf_i, inf.coords0[0]);
        })

        this.updateSvgPaths();


        // save the initial values for the stack
        if (this.stack.length == 0) {
            this.checkStack("Initial Data");
        }

        return infs;
    }

    claculatenumber_of_loops = (seconds_needed) => {
        let loops = 0;
        if (seconds_needed > this.fileDuration) {
            loops = (seconds_needed * SAMPLES_PER_SECOND - (this.track.loop.clean_start_length - this.track.loop.ending_length)) / this.track.loop.loop_length;
        }
        if (loops < 0) {
            loops = 0;
        }
        return Math.ceil(loops);
    }

    setInputValue = (element, value) => {
        if ($(element)) {
            $(element).value = value;
        }
    }

    calculateValuesFoMusicSeconds = async (seconds) => {

        if (!seconds) {
            seconds = this.fileDuration;
        }


        if (this.minimum_music_length && seconds < this.minimum_music_length) {
            this.dispatchEvent('music-is-too-short', this.minimum_music_length);
            console.error(`Minimum music length is ${this.minimum_music_length}`)
            return false;
        }

        this.music_length_required = Number(seconds);

        //first we get it from the track;
        this.loop_length_in_bars = parseInt(this.track.loop_length_in_bars);
        // if (seconds > this.duration) {
        //     this.number_of_loops = this.claculatenumber_of_loops(this.music_length_required);
        // } else {
        //     this.number_of_loops = 0;
        // }
        this.force_song_end = this.music_length_required;
        this.loop_starts_at_bar = this.loop_length_in_bars + 1;

        this.song_resolution = this.calculate_song_resolution();

        this.setInputValue("#loop-length", this.loop_length_in_bars);
        // this.setInputValue("#loop-number", this.number_of_loops);
        this.setInputValue("#song-end", this.force_song_end);
        this.setInputValue("#loop-start-bar", this.loop_starts_at_bar);

        if (this.music_length_required && this.music_length_required > 0) {
            let res = await this.maybeUseAlgorithm('calculateValuesFoMusicSeconds');
            if (!res) { }
        }

        await this.calculateValues('calculateValuesFoMusicSeconds');

        console.log(`calculateValuesFoMusicSeconds for ${seconds}`);
        setTimeout(() => {
            this.createDatapoints();
        }, 50);

        this.stopPlay();

        return true;

    }
    maybeUseAlgorithm = async (loc = '') => {

        const { number_of_loops_required__C16, global_skip_from, global_skip_to, offset_in_seconds__C6, total_music_in_bars__C5, audio_length__C4, section_1_safezone_seconds__C25, section_3_safezone_seconds__C28 }
            = await Algorithm.calculate(this.music_length_required,
                {
                    time_signature: this.track.timeSignature[0],
                    bpm: this.track.bpm,
                    offset: this.offset,
                    loop_length_in_bars: this.track.loop_length_in_bars,
                    clean_start: this.track.loop.clean_start_length,
                    loop_length: this.track.loop.loop_length,
                    ending_length: this.track.loop.ending_length,
                    file_duration: this.fileDuration,
                    section_1_safezone_amend_by_x_bars: this.track.section_1_safezone_amend_by_x_bars || 0,
                    dynamic_input_to_amend_safe_zone_length_by_1_bar: parseInt($("#cut-down-value").value),
                    algorithm_mode: this.algorithm_mode
                }
            )

        this.minimum_music_length = Number((section_1_safezone_seconds__C25 + section_3_safezone_seconds__C28).toFixed(2));
        console.log(`Music Required is ${this.music_length_required}`)
        console.log(`Minimum music length is ${this.minimum_music_length}`)
        if (number_of_loops_required__C16 >= 0) {
            this.number_of_loops = number_of_loops_required__C16;
            this.setInputValue('#loop-number', number_of_loops_required__C16);
        } else {
            this.setInputValue('#loop-number', 0);
        }
        if (typeof global_skip_from !== 'undefined' && parseFloat(global_skip_from)) {
            this.setInputValue('#song-skip-from', global_skip_from);
        } else {
            this.setInputValue('#song-skip-from', '');
        }

        if (typeof global_skip_to !== 'undefined' && parseFloat(global_skip_to)) {
            this.setInputValue('#song-skip-to', global_skip_to);
        } else {
            this.setInputValue('#song-skip-to', '');
        }
        this.dispatchEvent( 'algorithm-calculations-finished',{...this} )
        return await Promise.resolve( true );
    }
    calculateValues = async (loc) => { // check_4



        let bpm = parseFloat(this.track.bpm);

        // ( clean start - offset + loop length + ending length ) / 48000

        this.offset = this.track.offset / SAMPLES_PER_SECOND;
        this.duration = this.fileDuration - this.offset;//(this.track.loop.clean_start_length - this.track.offset + this.track.loop.loop_length + this.track.loop.ending_length) / SAMPLES_PER_SECOND;

        this.bar_length = 60 / bpm * this.track.timeSignature[0];



        this.readInputValues()



        if (this.one_time_skip_from != "") {
            this.skip_length = Tone.Time(this.one_time_skip_to) - Tone.Time(this.one_time_skip_from)
        }

        this.realDuration = this.duration + (this.loop_length_in_bars * this.number_of_loops * this.bar_length) - this.skip_length;;


        // not sure why the calbration need this?
        if (this.track.name == "calibration") {
            this.realDuration += bar_length
        }


        this.number_of_bars = this.duration / this.bar_length;

        this.real_num_of_bars = this.realDuration / this.bar_length

        // this.realDuration = this.realDuration.toFixed(1)
        if (this.force_song_end < this.realDuration) {
            this.realDuration = this.force_song_end
        }
        // console.log(this.duration, this.bar_length, this.realDuration, this.number_of_loops)
        this.dispatchEvent('audioengine-calculate-values',{...this});

        this.debug_data();

        return await Promise.resolve(123);
    }

    debug_data() {
        let temp = ""
        temp += "BPM: " + this.track.bpm
        temp += "\nOffset: " + this.offset.toFixed(2) + "s (" + this.track.offset + ")"

        temp += "\nClean Start: " + (this.track.loop.clean_start_length / SAMPLES_PER_SECOND).toFixed(2);
        temp += "\nLoop Length: " + (this.track.loop.loop_length / SAMPLES_PER_SECOND).toFixed(2);
        temp += "\nLoop End: " + (this.track.loop.ending_length / SAMPLES_PER_SECOND).toFixed(2);
        temp += "\nVALUES: " + ((Number(this.track.loop.clean_start_length / SAMPLES_PER_SECOND)) + Number(this.track.loop.loop_length / SAMPLES_PER_SECOND) + Number(this.track.loop.ending_length / SAMPLES_PER_SECOND));
        temp += "\nSignature: " + this.track.timeSignature // check_4
        temp += "\nConfig Duration: " + this.duration.toFixed(1)
        temp += "\nMP3 Files Duration: " + this.fileDuration.toFixed(1)
        temp += "\nBars: " + this.number_of_bars.toFixed(1)
        temp += "\nBar length: " + this.bar_length.toFixed(1) + "s"
        temp += "\n---calculated---"
        temp += "\nReal Duration: " + this.realDuration.toFixed(1) + "s"
        temp += "\nReal # Bars: " + this.real_num_of_bars.toFixed(1)

        if (this.one_time_skip_from == "") {
            temp += "\nnot skipping"
            temp += "\n" + this.one_time_skip_from
        } else {
            temp += "\nSkipping!"
            temp += ` from  ${parseFloat(Tone.Time(this.one_time_skip_from).toSeconds()).toFixed(1)}s`
            temp += ` to  ${parseFloat(Tone.Time(this.one_time_skip_to).toSeconds()).toFixed(1)}s`
            temp += ` length: ${this.skip_length.toFixed(1)}s \n`
            temp += `Skipping from ${this.one_time_skip_from} to ${this.one_time_skip_to} bar/beat`
        }



        $("#log0").innerText = temp
        $("#log4").innerText = "duration: " + this.realDuration.toFixed(1)

    }

    startTransport = async (play = false) => {




        await this.calculateValues('starttransport')
        this.timeCounter = 0
        this.current_time_index = 0;

        this.transport.cancel(0)
        this.doSchedule(this.transport)


        this.toggle = true

        if (this.song_starts_sec == 0) {
            this.loopCounter = this.number_of_loops;//+ 1
            this.playersA.forEach(el => { el.sync().start(0) });
            this.playersB.forEach(el => { el.sync().start(0) });


            this.transport.position = this.track.offset / SAMPLES_PER_SECOND // check_4
        } else {
            this.transport.position = this.song_starts_sec;
        }
        // sync virtual timeline
        const [bar, beat] = this.transport.position.split(":").map(x => parseInt(x));
        this.virtBar = bar;
        this.virtBeat = beat - 1 // check_4
        // if (this.track.timeSignature[0] == 4) this.virtBeat -= 1
        // this.transport.start("+0.1")
        if (play) {
            this.transport.start();
        }

        if (!this.transport_started) {
            this.transport_started = true;
        }
    }

    NEW_calculateLoopAndSkip = (pars, val) => {
        if (!pars) {
            pars = {
                offset: this.track.offset / 48000,
                loopNum: this.number_of_loops,
                loopStart: this.loop_starts_at_bar,
                loopLength: this.loop_length_in_bars,
                bpm: this.track.bpm,
                signature: this.track.timeSignature[0],
                one_time_skip_from: this.one_time_skip_from,
                one_time_skip_to: this.one_time_skip_to,
            }
            pars.onebar = 60 / pars.bpm * pars.signature
        }

        // If no loops or skips active, preserve exact time
        if (pars.loopNum === 0 && !pars.one_time_skip_from) {
            const rbar = Math.floor(val / pars.onebar)
            const rbeat = Math.floor((val / pars.onebar % 1) * pars.signature)
            return {
                val,
                new_val: val,
                l1: 0,
                l2: 0,
                rbarbeat: `${rbar}:${rbeat}`,
                vbarbeat: `${rbar}:${rbeat}`,
                vbar: rbar,
                vbeat: rbeat,
                rbar
            }
        }

        let theBar = val / pars.onebar
        let l1 = Math.floor((theBar - pars.loopStart) / pars.loopLength)
        let l2 = l1

        l1 = Math.max(0, Math.min(l1, pars.loopNum))
        l2 = Math.max(0, Math.min(l2, pars.loopNum))
        l2 = pars.loopNum - l2

        // Calculate real and virtual bars/beats
        const rbar = Math.floor(val / pars.onebar)
        const rbeat = Math.floor((val / pars.onebar % 1) * pars.signature)

        let vbar = rbar
        let vbeat = rbeat

        if (theBar >= pars.loopStart && pars.loopNum > 0) {
            vbar = rbar - (l1) * pars.loopLength
        }

        let new_val = pars.onebar * vbar + pars.onebar * vbeat / pars.signature

        // Handle skips
        if (l2 === 0 && pars.one_time_skip_from) {
            const [skipFromBar, skipFromBeat] = pars.one_time_skip_from.split(":").map(x => parseInt(x))
            const skipFromTime = pars.onebar * (skipFromBar + (pars.loopNum) * pars.loopLength) +
                               pars.onebar * skipFromBeat / pars.signature

            const [skipToBar, skipToBeat] = pars.one_time_skip_to.split(":").map(x => parseInt(x))
            const skipToTime = pars.onebar * skipToBar + pars.onebar * skipToBeat / pars.signature

            if (val > skipFromTime) {
                new_val = val + (skipToTime - skipFromTime)
                vbar = Math.floor(new_val / pars.onebar)
                vbeat = Math.floor((new_val / pars.onebar % 1) * pars.signature)
            }
        }

        return {
            val,
            l1,
            l2,
            new_val,
            rbarbeat: `${rbar}:${rbeat}`,
            vbarbeat: `${vbar}:${vbeat}`,
            vbar,
            vbeat,
            rbar
        }
     }

     calculateLoopAndSkip = (pars, val) => {

        if (!pars) {
            pars = {
                offset: this.track.offset / 48000,
                // bars: parseInt(this.num_of_bars),
                loopNum: this.number_of_loops,
                loopStart: this.loop_starts_at_bar,
                loopLength: this.loop_length_in_bars,
                bpm: this.track.bpm,
                signature: this.track.timeSignature[0],

                one_time_skip_from: this.one_time_skip_from,
                one_time_skip_to: this.one_time_skip_to,
            }
            pars.onebar = 60 / pars.bpm * pars.signature
        }

        let l1 = 0
        let l2 = 0
        // l = (pars.loopLength - (val / pars.onebar - pars.loopStart) / pars.loopLength)
        // l = (pars.loopLength - (val / pars.onebar - pars.loopStart - 1) / pars.loopLength)

        let theBar = val / pars.onebar
        l1 = (theBar - pars.loopStart) / pars.loopLength
        l1 = Math.floor(l1)
        l2 = (theBar - pars.loopStart) / pars.loopLength
        l2 = Math.floor(l2)


        let new_val = 0

        let rbar = 0
        let rbeat = 0

        rbar = val / pars.onebar + 0
        rbar = Math.floor(rbar)

        rbeat = val / pars.onebar * pars.signature - rbar * pars.signature
        rbeat = (rbeat)

        let rbarbeat = rbar + ":" + rbeat

        let vbar = rbar
        let vbeat = rbeat



        l1 = l1 < 0 ? 0 : l1
        l1 = l1 > (pars.loopNum + 0) ? (pars.loopNum + 0) : l1

        // l2 += 1
        l2 = l2 < 0 ? 0 : l2
        l2 = l2 > (pars.loopNum + 0) ? (pars.loopNum + 0) : l2

        // reverse
        // l1 = pars.loopNum - l1 + 1
        l2 = pars.loopNum - l2

        if (theBar >= pars.loopStart && pars.loopNum > 0) {
            vbar = rbar - (l1 - 0) * pars.loopLength //  + pars.loopStart + 1
        }






        new_val = pars.onebar * vbar + pars.onebar * vbeat / pars.signature

        // skip
        let h = 0
        let skipFromTime = 0
        let skipToTime = 0
        let skipDifference = 0

        if (l2 == 0) {

            let [skipFromBar, skipFrombeat] = pars.one_time_skip_from.split(":").map(x => parseInt(x))
            skipFromBar += (pars.loopNum - 0) * pars.loopLength  // + pars.loopStart + 1
            skipFromTime = pars.onebar * skipFromBar + pars.onebar * skipFrombeat / pars.signature

            const [skipToBar, skipToBeat] = pars.one_time_skip_to.split(":").map(x => parseInt(x))
            skipToTime = pars.onebar * skipToBar + pars.onebar * skipToBeat / pars.signature

            skipDifference = skipToTime - skipFromTime

            if (val > skipFromTime) {
                new_val = val + skipDifference
                vbar = Math.floor(new_val / pars.onebar)
                vbeat = ((new_val / pars.onebar - vbar) * pars.signature)
            }
        }

        let vbarbeat = vbar + ":" + vbeat // check_5


        new_val = parseInt(100 * new_val) / 100 + pars.offset
        val = pars.onebar * rbar + pars.onebar * rbeat / pars.signature + pars.offset
        val = parseInt(100 * val) / 100


        let result = { val: val, l1: l1, l2: l2, new_val: new_val, rbarbeat: rbarbeat, vbarbeat: vbarbeat, vbar: vbar, vbeat: vbeat, rbar: rbar }

        return result
    }


    setPlayheadTime = (val) => {
        if (!this.transport) return

        if (val == 0) val == this.track.offset / SAMPLES_PER_SECOND
        if (val < 0) {
            val = 0;
        }

        if (this.is_playing) {
            this.paused_seconds = null;
        } else {
            this.paused_seconds = val;;
        }

        if (this.transport.state != "started") {
            // return;
            // this.startTransport()
            // return;
        }
        // Add offset before calculations

        let result = this.calculateLoopAndSkip(null, val) // check_5

        this.loopCounter = result.l2

        // this.songStartSec = result.new_val

        this.helperBar = result.rbar
        this.virtBar = result.vbar
        this.virtBeat = result.vbeat

        this.transport.position = result.val + "s"
        // this.transport.position = (result.val + this.offset) + "s";


        if (result.new_val < 0) {
            console.error("shouldnt happen!")
            result.new_val = 0;
        }


        // force only playersA playing
        this.toggle = true
        this.crossFade.fade.value = 0

        if (this.transport.state != "started") {
            // not playing so dont restart players?
            return;
        }

        this.playersA.forEach((el, i) => { el.restart(Tone.now(), result.new_val) });
        this.playersB.forEach((el, i) => { el.restart(Tone.now(), result.new_val) });
    }

    setTransportPosition = ( time = false ) => {
        if( time == false  && isNaN(time)){
            time = this.current_time_index / 10;
        }
        this.setPlayheadTime(time);
        this.current_time_index = ~~(time * 10); // every second is 10 points!
        // $("#log3").innerText = bar + ":" + beat + " " + time;
        let indx = parseInt(time * 10);

        this.setSliderValuesOnCurrentIndex(indx);
        // this.timeCounter = time;
    }
    setSliderValuesOnCurrentIndex(index = 0) {
        this.inflections.forEach((inf, inf_i) => {
            this.setSliderValue(inf_i, inf.coords0[index]);
        })
    }

    stopTransport = ( calculate = true ) =>  {

        if( this.transport ){
            this.transport.stop();
            this.setTransportPosition(0);
            this.transport.position = this.track.offset / 48000;
        }

        this.timeCounter = 0;
        this.current_time_index = 0
        if (calculate) {
            this.calculateValues('stoptransport');
        }
        if (this.renderPlayer) {
            this.renderPlayer.stop()
        }
        this.dispatchEvent('stop-transport',{});

    }

    setInflectionRangeValues = (index, from, to, value, ramp = false) => {
        from = parseInt(from);
        to = parseInt(to);
        const start = Math.max(0, Math.min(from - 1, this.inflections[index].coords0.length - 1));
        const end = Math.max(0, Math.min(to + 1, this.inflections[index].coords0.length - 1));

        const rangeLength = end - start + 1;
        const step = value / (rangeLength - 1);

        for (let i = 0; i < rangeLength; i++) {
            if (ramp) {

                let v = i * step;
                if (v > value) { v = value; }
                this.setCoordValue(index, start + i, v);
            } else {
                this.setCoordValue(index, start + i, value);
            }
        }
        // this.dispatchEvent('set-inflections-in-range',{start:from,end:to,value});
        // this.updateSvgPaths(index);
    }


    calculate_song_resolution = () => {

        let ticks = 192;
        let bpm = parseFloat(this.track.bpm);
        let ticks_per_minute = bpm * ticks;
        let values_per_second_required = 10;
        let values_per_minute = 60 * values_per_second_required;
        let new_song_resolution = parseInt(ticks_per_minute / values_per_minute);

        this.setInputValue("#song-resolution", new_song_resolution);

        return new_song_resolution;
    }

    loadSession(data) {
        this.inflections = this.data.inflections;
        if (data.opts) {

        }
        this.updateSvgPaths();
    }
    bufferToWave = (abuffer, len, sample_rate) => {
        let sampleRate = sample_rate
        var numOfChan = abuffer.numberOfChannels,
            length = len * numOfChan * 2 + 44,
            buffer = new ArrayBuffer(length),
            view = new DataView(buffer),
            channels = [], i, sample,
            offset = 0,
            pos = 0;



        // write WAVE header
        setUint32(0x46464952);                         // "RIFF"
        setUint32(length - 8);                         // file length - 8
        setUint32(0x45564157);                         // "WAVE"

        setUint32(0x20746d66);                         // "fmt " chunk
        setUint32(16);                                 // length = 16
        setUint16(1);                                  // PCM (uncompressed)
        setUint16(numOfChan);
        // setUint32(abuffer.sampleRate);
        // setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
        setUint32(sampleRate);
        setUint32(sampleRate * 2 * numOfChan); // avg. bytes/sec
        setUint16(numOfChan * 2);                      // block-align
        setUint16(16);                                 // 16-bit (hardcoded in this demo)

        setUint32(0x61746164);                         // "data" - chunk
        setUint32(length - pos - 4);                   // chunk length

        // write interleaved data
        for (i = 0; i < abuffer.numberOfChannels; i++)
            channels.push(abuffer.getChannelData(i));

        while (pos < length) {
            for (i = 0; i < numOfChan; i++) {             // interleave channels
                sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
                sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0; // scale to 16-bit signed int
                // sample *= 32768
                view.setInt16(pos, sample, true);          // write 16-bit sample
                pos += 2;
            }
            offset++                                     // next source sample
        }

        // create Blob
        return new Blob([buffer], { type: "audio/wav" });

        function setUint16(data) {
            view.setUint16(pos, data, true);
            pos += 2;
        }

        function setUint32(data) {
            view.setUint32(pos, data, true);
            pos += 4;
        }
    }

    make_download(abuffer, total_samples) {

        var blob = this.bufferToWave(abuffer, total_samples, this.sample_rate);

        // Generate audio file and assign URL
        var new_file = URL.createObjectURL(blob);
        var d = new Date();
        var name = "render_" + d.toISOString() + ".wav";

        const formattedDate = d.toLocaleString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "2-digit"
        });


        let obj = {
            name: "THEME NAME HERE",
            blob_url: new_file,
            blob: blob,
            file_name: name,
            date: formattedDate
        }

        this.dispatchEvent('loading-message', `Rendering complete!`);
        setTimeout(() => {
            this.dispatchEvent('render-finished', obj)
        }, 300);
    }
    removeWatermark = () => {
        this.use_watermark = false;
        if (this.transport && this.watermark_schedule) {
            this.transport.clear(this.watermark_schedule);
        }
    }

    async destroy() {
        clearInterval(this.meterInterval)
        this.stopTransport(false);


        this.playersA.forEach(p => p.dispose());
        this.playersB.forEach(p => p.dispose());
        this.playersA = [];
        this.playersB = [];
        this.skip_length = 0;
        this.song_starts_sec = 0;
        this.number_of_loops = 0;
        this.one_time_skip_from = '';
        this.one_time_skip_to = ''
        this.loopCounter = 0;
        this.virtBar = -1;
        this.virtBeat = -1;
        this.offset = 0;
        this.toggle = false;
        this.track = false;
        this.loop_length_in_bars;
        this.music_length_required = null
        this.stack = [];
        this.inflections = Array(3).fill().map(() => ({
            coords0: [],
            nodes: []
        }))

        await this.wait( 3000 );
        this.listeners.clear();

    }


}

export default (new AudioEngine());