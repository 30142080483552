<template>
    <div class="range-slider-wrapper" v-bind="$attrs" >
        <filmstro-icon @click="$emit('leftIconClick')" v-if="left_icon" :name="left_icon" :type="left_icon_type" />
        <input :id="id" @change="onChange($event.target.value)" @input="$emit('update:modelValue', $event.target.value)"
            type="range" :min="min" :max="max" class="slider range-slider" :value="value" :step="step"/>
        <filmstro-icon @click="$emit('rightIconClick')" v-if="right_icon" :name="right_icon" :type="right_icon_type" />
    </div>
</template>

<script>
export default {
    // emits:[''],
    props:{
        left_icon:{
            default:false
        },
        left_icon_type:{
            default:"icon"
        },
        right_icon_type:{
            default:"icon"
        },
        right_icon:{
            default:false
        },
        min:{
            default:0
        },
        max:{
            default:100
        },
        value:{
            default:20
        },
        step:{
            default:1
        },
        id:{
            default:"range-slider"
        }
    },
    data(){
        return {
            slider_value: this.value
        }
    },
    methods:{
        onChange(value){
            this.$emit('changed', value)
            document.activeElement.blur();
        }
    }

}
</script>


<style scoped>
    .range-slider-wrapper {
        display: inline-flex;
        align-items: center;
        height: 20px;
    }

    .range-slider-wrapper img {
        width: 16px;
        height: 16px;
        object-fit: contain;
        /* margin-right: 5px; */
    }

    .range-slider-wrapper input {
        margin: 0 10px;
    }
    .range-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    background: #9b9b9b;
    outline: none;
    border: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    margin: 0 0 4px 0;
    }

    .range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 17px;
    height: 17px;
    border-radius: 100px;
    background: #fff;
    border: none;
    cursor: pointer;
    }

    .range-slider::-moz-range-thumb {
    width: 17px;
    border-radius: 100px;
    height: 17px;
    background: #fff;
    cursor: pointer;
    border: none;
    }

</style>