<template>
    <modal class="about-filmstro-modal" v-if="show_about_filmstro_modal" @close="closeModal" :show_the_content="show_about_filmstro_modal" :show_close_button="true" @backdropclick="closeModal">
        <template v-slot:header>
            <div class="modal-header">
                <img loading="lazy"  src="@/assets/filmstro-logo.png" class="filmstro-logo" alt="logo">
                <h3>Filmstro Pro</h3>
            </div>
        </template>
        <template v-slot:body>
            <div class="about-version"><small>v{{$version}}</small></div>
            <div class="about-copyright">©2014-{{ new Date().getFullYear() }} Filmstro. All rights reserved.</div>
            <div class="about-credits">
                <span>Sebastian Jaeger</span>, <span>Perlat Kociaj</span>, <span>Christopher Young</span>, <span>Timothy Fletcher</span>, <span>Dirk Krause</span>
            </div>
            <div class="about-terms">
                <a target="_blank" href="https://filmstro.com/terms-and-conditions">Terms And Conditions</a>
            </div>
        </template>
        <template v-slot:footer>
            <div class="about-footer-logo">
                <img loading="lazy"  src="@/assets/logo-desktop.svg" alt="logo">
            </div>
        </template>
    </modal>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
export default {
    setup(){
        const store = useStore();
        let show_about_filmstro_modal = computed(()=>{
            return store.getters.show_about_filmstro_modal;
        });
        const closeModal = ()=>{
            store.commit('setShowAboutFilmstroModal',false);
        }
        return {
            show_about_filmstro_modal,
            closeModal
        }
    }
}
</script>

<style lang="scss">
.about-filmstro-modal{
    .modal-content{
        // background: linear-gradient(132deg, #fffdf3 0, #999a9b 130.15%);
        background-color: #d8d8d8;
        min-width: 400px;
        border-radius: 20px!important;
    }
    .filmstro-logo{
        width: 50px;
    }

    .modal-header {
        display: flex;
        align-items: center;
        padding: 30px;
    }

    .modal-header h3 {
        margin-left: 20px;
        font-size: 40px;
        color: var(--filmstro-black);
    }


    .modal-body {
        text-align: left!important;
        color: var(--filmstro-gray);
        font-size: 16px;
        min-height: 260px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        justify-content: space-between;
    }

    .modal-dialog {
        max-width: 700px!important;
    }

    .about-footer-logo {
        padding: 20px;
        display: flex;
        justify-content: flex-end;
    }

    .about-footer-logo img {
        width: 140px;
    }



}
</style>