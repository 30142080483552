<template>
    <header class="main-header">
        <div class="top-bar-logo-wrapper" v-if="$route.name == 'browse'">
            <figure class="logo-figure">
                <a target="_blank" href="https://filmstro.com"><img loading="lazy" src="@/assets/v4logo.webp" class="logo"
                        alt="logo"></a>
            </figure>
            <filmstro-icon name="home" type="image" @click="goToHome" />
        </div>
        <ul class="top-nav">

            <li :class="{ 'active': $route.name == 'browse' && $route.params.collection != 'favourites' }"
                v-tooltip="`Back to browse`"> <a
                    @click="goToHome" href="#">{{ $t('top_menu.browse') }}</a></li>
            <li REMOVEv-tippy="{ content: `This tab opens the edit page. <br> You need to select a track first and add it by clicking the pencil icon on the track card or clicking the 'customise music' button. `, placement: 'bottom' }"
                class="edit-top-link" v-if="$route.name == 'edit'" :class="{ 'active': $route.name == 'edit' }">
                <router-link :to="get_edit_screen_link">{{ $t('top_menu.edit') }}</router-link>
            </li>
            <li v-tooltip="`Open, rename and delete sessions.`"
                :class="{ 'active': current_edit_view == 'my-sessions' }"><a
                    @click.prevent="maybeOpeSidebar('my-sessions')" href="#">{{ $t('top_menu.my_sessions') }}
                    <filmstro-icon v-if="!USER_IS_LOGGED_IN" name="padlock" type="image" /></a></li>
            <li id="my-favourites-link" v-tooltip="`Displays a list of your favourite tracks`"
                :class="{ 'active': $route.params.collection == 'favourites' }">
                <!-- <router-link :to="'/browse/favourites/me'">
                    {{ $t('top_menu.my_favourites') }}
                    <filmstro-icon v-if="!USER_IS_LOGGED_IN" name="padlock" type="image" />
                    <span class="top-bar-menu-badge"><span class="top-bar-badge-count">{{ user_favourites.length}}</span></span>
                </router-link> -->
                <a  @click.prevent="maybeViewFavourites()" href="#">{{ $t('top_menu.my_favourites') }}
                    <filmstro-icon v-if="!USER_IS_LOGGED_IN" name="padlock" type="image" />
                    <span class="top-bar-menu-badge"><span class="top-bar-badge-count">{{ user_favourites.length}}</span></span>
                </a>
            </li>

        </ul>
        <div class="top-bar-toggle top-bar-middle-section noselect">
            <span v-if="current_session && current_session.title"> {{ current_session.title }}</span>
            <!-- <img src="https://filmstro.com/wp-content/themes/filmstro/dist/images/logo-desktop.svg"/> -->
            <!-- <img class="filmstro-top-bar-logo" src="/filmstro-v3-logo.png" /> -->
        </div>

        <div class="topBar-right-icons">

            <!-- <button class="calear-button top-right-login-btn" @click="burgerMenu = !burgerMenu">
                <img v-show="burgerMenu" src="@/assets/images/burger-menu.png" alt="burger menu" class="burger-menu-icon" />
                <img v-show="!burgerMenu" src="@/assets/images/burger-menu-active.png" alt="burger menu active" class="burger-menu-icon" />
            </button> -->

            <ul class="top-nav right-side help-button">
                <li id="pulse-circle"></li>
                <li v-tooltip="`Link to our support page: Opens new tab`">
                    <div class="dropdown">
                        <button title="Help & support" class="dropbtn">Support <filmstro-icon name="arrow-down-icon" /></button>
                        <div class="dropdown-content">
                            <a href="https://filmstro.com/handbook/intro-to-filmstro-web-app/" target="_blank">Handbook</a>
                            <a @click="$store.commit('setShowTutorialModal', true)">Watch tutorial</a>
                            <a :href="`${website_url}/contact`" target="_blank">Contact Us</a>
                        </div>
                    </div>
                </li>
                <li v-tooltip="`Link to your account: Opens new tab`" v-if="USER_IS_LOGGED_IN">
                    <!-- <a :class="{ 'active': helpActive }" :href="account_url">
                        &nbsp;<filmstro-icon class="default-icon" name="my-account" />
                    </a> -->
                    <div class="dropdown">
                        <button title="Account and sample rate change" class="dropbtn" @mousemove="ondropdownButtonMouseMove($event)" @mouseenter.self="ondropdownButtonMouseEnter">
                            <img v-if="USER_IS_LOGGED_IN" :src="CURRENT_USER?.avatar_url" class="dropdown-profile-icon" />
                            <filmstro-icon v-else class="default-icon dropdown-profile-icon" name="my-account" />
                        </button>
                        <div class="dropdown-content">
                            <div class="profile-dd" v-if="USER_IS_LOGGED_IN" :class="{ 'active': user_has_active_license }">
                                <span>{{ greeting }}</span>
                                <small>{{ CURRENT_USER?.email }}</small>
                            </div>
                            <a v-if="USER_IS_LOGGED_IN" target="_blank" :href="account_url">My Account</a>
                            <a v-if="USER_IS_LOGGED_IN" :href="`${website_url}/my-account/licences/`" target="_blank">My Plans</a>
                            <a v-if="USER_IS_LOGGED_IN" @click="$store.commit('setPreferencesModalVisible', true)">Settings</a>
                            <a v-if="theme_details && USER_IS_LOGGED_IN && CURRENT_USER && CURRENT_USER.is_admin" :href="`${website_url}/wp-admin/post.php?post=${theme_details.theme_id}&action=edit`" target="_blank">View in CMS</a>
                            <a av-if="theme_details && USER_IS_LOGGED_IN && CURRENT_USER && CURRENT_USER.is_admin" :href="theme_details && theme_details.theme_name ? `https://v3.filmstro.com/edit/${theme_details.theme_name}` : 'https://v3.filmstro.com'" target="_blank">Open V3 App</a>
                            <a class="flex" >
                                <label for="" class="flex" @click="$store.commit('setShowShortcutsKeyboardModal',true)">
                                    Shortcuts
                                    <filmstro-icon type="image" name="shortcuts-keyboard" />
                                </label>
                            </a>
                            <!-- <a v-if="$route.name == 'browse' &&USER_IS_LOGGED_IN && CURRENT_USER && CURRENT_USER.is_admin"> <CircleToggleButton @change="$store.commit('browse/setUseNewSearch',!use_new_search)" id="use_new_searc" size="5" label="Use New Search" description="" title="use the new version of searching" :color="use_new_search ? '#0f0' : '#f00'" value="0" /></a> -->
                            <a v-if="USER_IS_LOGGED_IN && CURRENT_USER && CURRENT_USER.is_admin" > <CircleToggleButton @change="$store.commit('setEnableDebugForFdmin',!enable_debug_for_admin)" id="enable_debug_for_admin" size="5" label="Admin Debug" description="" title="Enable Debugging for Admin" :color="enable_debug_for_admin ? '#0f0' : '#f00'" value="0" /></a>
                            <a  @click="showAboutModal">Info</a>
                            <a v-if="USER_IS_LOGGED_IN" @click="logOut">Log Out</a>
                        </div>
                    </div>
                </li>
            </ul>

            <button v-if="!USER_IS_LOGGED_IN && ($route.name == 'edit' || $route.name == 'browse')"
                @click="showRemoveWaterMarkModal" class="calear-button top-right-login-btn">
                <filmstro-icon class="default-icon" name="my-account" />
            </button>

            <!-- <button class="calear-button top-right-login-btn" @click="bellIcon = !bellIcon">
            <img v-show="bellIcon" src="@/assets/images/bell-icon.png" alt="bell" class="bell-icon" />
            <img v-show="!bellIcon" src="@/assets/images/bell-icon-active.png" alt="bell-active" class="bell-icon" />
        </button> -->
        </div>
    </header>
</template>
<script>


import { mapGetters, mapMutations } from 'vuex'
import { account_url, website_url } from '@/includes/helpers';
import CircleToggleButton from '@/components/CircleToggleButton.vue';
export default {
    name: "TopBar",
    components: {
        CircleToggleButton
    },
    data() {
        return {
            burgerMenu: true,
            bellIcon: true,
            helpActive: false
        };
    },
    computed: {
        ...mapGetters(['user_has_active_license', 'user_favourites', 'USER_IS_LOGGED_IN', 'CURRENT_USER', 'enable_debug_for_admin']),
        ...mapGetters('edit', ['current_session', 'last_edit_link', 'current_edit_view', 'theme_details']),
        ...mapGetters('browse', ['breadcrumbs', 'selected_theme', 'use_new_search']),
        // ...mapGetters('auth',['USER_IS_LOGGED_IN']),
        account_url() {
            return account_url();
        },
        greeting() {
            if (this.USER_IS_LOGGED_IN) {
                return `Hi ${this.CURRENT_USER?.firstName.substring(0, 14)} 👋`;
            } else {
                return `Hi 👋`;
            }
        },
        website_url() {
            return website_url();
        },
        get_edit_screen_link() {
            if (this.$route.name == 'edit') {
                return this.$route.fullPath;
            }
            let url = "/edit";
            if (this.selected_theme) {
                url = `/edit/${this.selected_theme.theme_name}`
            }
            if (this.current_session) {
                url = `/edit/${this.current_session.theme}/main/${this.current_session.session_id}`
            }
            if (this.last_edit_link) {
                url = this.last_edit_link;
            }
            return url;
        },
        get_browse_link() {

            let url = '/browse';
            if (this.breadcrumbs.length && this.$route.name !== 'browse') {
                let _bcumb = this.breadcrumbs[this.breadcrumbs.length - 1];
                if (_bcumb.link) {
                    url = _bcumb.link;
                }
            }
            return url;
        }
    },
    mounted() {

    },
    methods: {
        showAboutModal() {
            let x = this.$store.getters['show_about_filmstro_modal'];
            this.$store.commit('setShowAboutFilmstroModal', !this.$store.getters['show_about_filmstro_modal']);
        },
        ondropdownButtonMouseEnter(e) {
            e.target.parentNode.classList.add('active');
        },
        ondropdownButtonMouseMove(e) {
            if (e.offsetX < -50 || e.offsetY > 50 || e.offsetY < -50) {
                e.target.parentNode.classList.remove('active');
            } else {
                // e.target.parentNode.classList.add('active');
            }
        },
        toggleHelpActive() {
            this.helpActive = !this.helpActive;
        },

        ...mapMutations(['setFlyoutContentView']),
        maybeOpeSidebar(view) {
            if (this.USER_IS_LOGGED_IN) {
                this.setFlyoutContentView(view)
            } else {
                this.$store.commit('setShowLoginRequiredModal', true);
                // this.$store.commit('setShowPremiumFeatureModal',true);
                return false;
            }
        },
        maybeViewFavourites() {
            // this.$store.dispatch( 'browse/setFavouriteAlbum' );

            this.$router.push({
                name: 'browse',
                params: {
                    collection: 'favourites',
                    album: 'me'
                }
            })

            this.$store.dispatch('maybeSetFavouritesAlbum');
            this.$store.dispatch('fetchFavourites', true);

        },
        goToHome($event) {
            $event.preventDefault();
            this.$store.dispatch('browse/setDefaultCollectionAndFeaturedPlaylists', false);
            this.$router.push("/browse");
        },
        logOut() {
            this.$store.dispatch('logout').then(() => {
                this.$router.push({ name: 'browse' });
            })

        },
        // deprecated?
        goToProURL(url) {

            if (this.user_has_active_license) {
                this.$router.push(url);
            } else {
                this.$store.commit('setShowPremiumFeatureModal', true);
                return false;
            }
        },

        showRemoveWaterMarkModal() {
            this.$store.commit('setLoginModalVisible', true);
            return;
            let url = this.$route.path;
            this.$store.commit('setShowLoader', true);
            this.$store.commit('setLoaderMessage', 'Redirecting to login page')
            this.$store.dispatch('setLoginRedirectURL', url);
            return;
            if (!this.USER_IS_LOGGED_IN) {
                return;
            } else {
                if (!this.user_has_active_license) {
                    this.$store.commit('setLoaderMessage', 'Redirecting to pricing page')
                    window.open(`${website_url()}/pricing?origin=app`);
                    this.$store.commit('setShowLoader', false);
                }
            }
        }
    }
}
</script>


<style scoped>
a.active {
    color: #FBB402;
}
</style>

<style lang="scss">
.dropdown {
    // overflow: hidden;


    .dropbtn {
        font-size: 16px;
        border: none;
        outline: none;
        color: white;
        padding-bottom: 15px;
        background-color: inherit;
        font-family: inherit;
        margin: 0;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &.active {
        .dropbtn {
            &::before {
                content: "";
                position: fixed;
                top: 0;
                left: 0;
                background: transparent;
                width: 100%;
                height: 100%;
            }
        }
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        left: 0;
        min-width: 190px;
    }

    .dropdown-content>* {
        color: var(--filmstro-black);
        float: none;

        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
    }


    .dropdown-content a:hover {
        background-color: #ddd;
    }

    &:hover .dropdown-content {
        display: block;
    }
}


.filmstro-top-bar-logo {
    max-width: 130px;
    margin-top: 10px;
}

/* header */
.main-header {
    // background-color: #292938;
    background-color: #15151C;
    padding: 5px 25px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 60px;
    z-index: 9999999;
    // position: fixed;
    // top: 0;
    // left: 0;
    // width: 100%;

}

.top-nav {
    list-style: none;
    margin: 10px 0 0 0;
    min-width: 370px;
    z-index: 9999;


    &.right-side {
        margin-left: none;
        width: auto;
        min-width: 0;
        margin-right: 10px;
        display: flex;
        align-items: center;
    }

    li {
        display: inline-block;
        margin: 0 28px 0 0;

        a {
            color: #D8D8D8;
            font-size: 0.875rem;
            line-height: normal;
            display: flex;
            align-items: center;
            padding: 0 0 23px 0;
            position: relative;

            &:hover {
                color: var(--filmstro-yellow) !important;
            }
        }


        a::before {
            content: "";
            width: 100%;
            height: 2px;
            background-color: var(--filmstro-yellow);
            position: absolute;
            bottom: -2px;
            left: 0;
            opacity: 0;
        }

    }

    li:last-child {
        margin-right: 0;
    }
}


.left-top-nav {}

.topBar-right-icons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-right: 10px;

}

.main-header .filmstro-icon {
    max-width: 25px;
    max-height: 17px;
    margin-left: 5px;
}

.main-header ul li.edit-top-link a::before {
    background-color: var(--filmstro-blue);
}

.main-header ul li.active a {
    font-weight: 600;
    color: #fff;
}

.main-header ul li.active a::before {
    opacity: 1;
}

.filmstro-home-icon {
    margin-bottom: 12px;
}

.top-right-login-btn {
    border: none;
    width: 40px;
    height: 40px;
    background-color: transparent;
    // color: #9B9B9B;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 36px;
    // border-radius: 13px;
    // border: 2px solid #fb9102;
    display: table;
    cursor: pointer;
    // animation: shakeMe 5s 2 ease-in;
    // animation-delay: 10s;
    // animation-name: shakeMe;
    // animation-duration: 5s;
    // animation-iteration-count: 2;
    // animation-timing-function: linear;
    margin: 0px 10px 0px 10px;

}


.top-right-login-btn img {
    // margin: -2px 9px 0 0;
    display: inline-block;
    vertical-align: middle;
    // color: #9B9B9B;
}

.help-button {
    transform: translate(0, 10%);
    cursor: pointer;
}

.bell-icon {
    color: #fff;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.burger-menu-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}



.top-bar-middle-section {
    display: flex;
    gap: 10px;
    margin-top: -10px;
    z-index: 9999;
    margin-left: 40px;
    align-items: center;
    flex: 1;
    justify-content: space-between;

    img {
        margin-left: auto;
    }

    .filmstro-icon {
        max-width: 30px;
        max-height: 25px;
    }

    label {
        cursor: pointer;
    }
}

.top-bar-menu-badge {
    display: flex;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    background-color: var(--filmstro-yellow);
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: -15px;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    color: white;
    color: black;
    opacity: 0;
    pointer-events: none;
    transition: all .4s ease;

    &.show-badge {
        opacity: 1;
        top: -10px;
        ;
    }
}

figure.logo-figure {
    img{
        width: 33px;
    }
}
@media screen and (max-width:1500px) {
    .top-right-login-btn {
        max-width: 120px;
        height: 25px;
        line-height: 23px;
    }
}

.top-bar-logo-wrapper {
    width: 274px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;

    .filmstro-icon {
        cursor: pointer;
    }
}


@media screen and (max-width: 800px) {
    .top-bar-logo-wrapper {
        width: auto;
        width: 90px;
    }



    figure.logo-figure img {
        width: 22px;
    }

    .top-nav li {
        margin-right: 10px;
    }



    ul.top-nav {
        min-width: 0;
    }
}

img.dropdown-profile-icon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.profile-dd {
    small {
        font-size: 9px;
    }
}
</style>
