
function decimal_number_to_bar_beats(decimalNumber, timeSignature = 4) {
    if (isNaN(decimalNumber) || isNaN(timeSignature)) return false;
    let integerPart = Math.floor(decimalNumber);
    let remainingBeats = Math.round((decimalNumber - integerPart) * timeSignature);
    let totalBars = integerPart + Math.floor(remainingBeats / timeSignature);
    let beatsInIncompleteBar = remainingBeats % timeSignature;
    return `${totalBars}:${beatsInIncompleteBar}`;
}

class Algorithm {
    algorithm_mode = "best_match";
    constructor() {
        console.warn('Algorithm Intitiated');
    }

    async calculate(custom_length = 150, theme) {

        //without calculations
        let music_needed__A1 = Number(custom_length);
        let SAMPLES__C20 = 48000;
        let manual_insertion_value_for_non_dividable_loop_length__C39 = 4;
        let time_signature__C2 = Number(theme.time_signature) || 4;
        let bpm__C3 = Number(theme.bpm) || 100;
        let audio_length__C4 = Number(theme.file_duration);
        let offset_in_seconds__C6 = Number(theme.offset) || 0.6;
        let full_loop_length_in_bars_CMS__C7 = Number(theme.loop_length_in_bars) || 16;
        let clean_start_samples__C8 = Number(theme.clean_start) || 1958399;
        let loop_length_samples__C9 = Number(theme.loop_length) || 1843199;
        let ending_length_samples__C10 = Number(theme.ending_length) || 2188799;
        let seconds_per_minute = 60;

        let section_1_amend_by_x_bars__C44 = Number(theme.section_1_safezone_amend_by_x_bars) || 0; //will come from CMS
        let dynamic_input_to_amend_safe_zone_length_by_1_bar__C45 = Number(theme.dynamic_input_to_amend_safe_zone_length_by_1_bar) || 2 //will come from CMS
        let bar_length__C12 = seconds_per_minute / bpm__C3 * time_signature__C2;
        let section_1__C13 = ((full_loop_length_in_bars_CMS__C7 + 1 + section_1_amend_by_x_bars__C44)) * bar_length__C12
        let section_2__C14 = full_loop_length_in_bars_CMS__C7 * bar_length__C12
        let section_3__C15 = audio_length__C4 - (section_1__C13 + section_2__C14)

        if( theme.algorithm_mode ){
            this.algorithm_mode = theme.algorithm_mode;
        }

        let music_length__C30 = Number((audio_length__C4 - offset_in_seconds__C6).toFixed(2));

        let loop_start_at_bar__C22 = full_loop_length_in_bars_CMS__C7 + 1;


        // let skip_from__C17 = music_needed__A1 <= music_length__C30 ? H70 : H21;
        // let skip_to__C18 = music_needed__A1 <= music_length__C30 ? H71 : H22;

        let total_music_in_bars__C5 = music_length__C30 / bar_length__C12;

        let one_beat_as_decimal__C42 = 1 / time_signature__C2;

        let audio_length_check__C49 = section_1__C13 + section_2__C14 + section_3__C15;
        let music_length_check__C50 = audio_length_check__C49 - offset_in_seconds__C6;
        let global_skip_from;
        let global_skip_to;


        //loop length calc
        let loop_length_divided_by_4__C32 = full_loop_length_in_bars_CMS__C7 / 4;
        let rouned_down_loop_length_divided_by_4__C33 = Math.floor(loop_length_divided_by_4__C32);
        let interim_decimal_to_check__C34 = 1;
        let interim_decimal__C35 = loop_length_divided_by_4__C32 - rouned_down_loop_length_divided_by_4__C33;

        let minimum_loop_length_calculated__C36 = interim_decimal__C35 < interim_decimal_to_check__C34 ? (rouned_down_loop_length_divided_by_4__C33 >= manual_insertion_value_for_non_dividable_loop_length__C39 ? rouned_down_loop_length_divided_by_4__C33 : manual_insertion_value_for_non_dividable_loop_length__C39) : manual_insertion_value_for_non_dividable_loop_length__C39;
        // interim_decimal__C35 < interim_decimal_to_check__C34 ? manual_insertion_value_for_non_dividable_loop_length__C39 : rouned_down_loop_length_divided_by_4__C33
        let minumum_loop_length_in_bars__C37 = minimum_loop_length_calculated__C36
        let minimum_loop_length_in_seconds__C38 = minumum_loop_length_in_bars__C37 * bar_length__C12
        let minimum_loop_length_as_decimal__C40 = minumum_loop_length_in_bars__C37 / full_loop_length_in_bars_CMS__C7



        let diff_music_length_by_custom_length_seconds__F8;
        let how_many_loops_fit_in_diff__F9;
        let rounded_loops_fitting_in_diff__F10;
        let interim_rounding_diff_loop__F11;
        let interim_decimal_diff_check__F12;
        let partial_loop_shorter_or_longer__F6;
        let diff__H8;
        let longer_skip_from__H21;
        let longer_skip_to__H22;
        let section_1_safezone_bars__C24;
        let section_1_safezone_bars_position__C23;
        let section_1_safezone_seconds__C25;
        let section_3_safezone_bars_position__C26;
        let section_3_safezone_bars__C27;
        let section_3_safezone_seconds__C28;
        let shorter_diff_in_bars__H29;
        let section_2_present_and_bigger_than_min_loop__F34;
        let shorter_A_skip_from__H35;
        let shorter_A_skip_from__H36;
        let section_2_present_but_smaller_than_min_loop__F38;
        let shorter_B_skip_from__H44;
        let shorter_B_skip_to__H45;
        let section_2_not_present_but_s1SZ_or_more__F47;
        let shorter_C_skip_from__H52;
        let shorter_C_skipt_to__H53;
        let section_2_not_present_but_s1SZ_and_s3SZ_or_more__F54;
        let shorter_D_skip_from__H67;
        let shorter_D_skip_to__H68;

        section_1_safezone_bars__C24 = (minumum_loop_length_in_bars__C37 / 2) / dynamic_input_to_amend_safe_zone_length_by_1_bar__C45;
        section_1_safezone_bars_position__C23 = section_1_safezone_bars__C24 + 1;
        section_1_safezone_seconds__C25 = (section_1_safezone_bars_position__C23 * bar_length__C12) - offset_in_seconds__C6;

        section_3_safezone_bars_position__C26 = (full_loop_length_in_bars_CMS__C7 + loop_start_at_bar__C22) + (full_loop_length_in_bars_CMS__C7 - ((minumum_loop_length_in_bars__C37 / 2) / dynamic_input_to_amend_safe_zone_length_by_1_bar__C45));
        section_3_safezone_bars__C27 = total_music_in_bars__C5 - section_3_safezone_bars_position__C26;
        section_3_safezone_seconds__C28 = section_3_safezone_bars__C27 * bar_length__C12;



        diff__H8 = music_needed__A1 > music_length__C30 ? music_needed__A1 - music_length__C30 : music_length__C30 - music_needed__A1;

        if (music_needed__A1 > music_length__C30) {

            //Calculation for Making music LONGER ✅
            diff_music_length_by_custom_length_seconds__F8 = music_needed__A1 - music_length__C30;


            how_many_loops_fit_in_diff__F9 = diff_music_length_by_custom_length_seconds__F8 / section_2__C14
            rounded_loops_fitting_in_diff__F10 = Math.ceil(how_many_loops_fit_in_diff__F9);
            interim_rounding_diff_loop__F11 = Math.floor(how_many_loops_fit_in_diff__F9);

            interim_decimal_diff_check__F12 = how_many_loops_fit_in_diff__F9 - interim_rounding_diff_loop__F11;

            partial_loop_shorter_or_longer__F6 = interim_decimal_diff_check__F12 >= minimum_loop_length_as_decimal__C40 ? "longer" : "shorter";


            longer_skip_from__H21 = (partial_loop_shorter_or_longer__F6 === "longer") ? (loop_start_at_bar__C22 + (Math.floor(interim_decimal_diff_check__F12 * (full_loop_length_in_bars_CMS__C7 * time_signature__C2)) / time_signature__C2)) : (loop_start_at_bar__C22 + minumum_loop_length_in_bars__C37);
            global_skip_to = longer_skip_to__H22 = partial_loop_shorter_or_longer__F6 == "longer" ? loop_start_at_bar__C22 + full_loop_length_in_bars_CMS__C7 : loop_start_at_bar__C22 + full_loop_length_in_bars_CMS__C7 + (minumum_loop_length_in_bars__C37 - (Math.floor(interim_decimal_diff_check__F12 * (full_loop_length_in_bars_CMS__C7 * time_signature__C2)) / time_signature__C2))

        } else {





            //Calculation for making music SHORTER ✅

            //shorter A
            shorter_diff_in_bars__H29 = (Math.ceil((diff__H8 / bar_length__C12) * time_signature__C2)) / time_signature__C2;

            // If s2 present and => than min loop ✅
            //shorter A
            section_2_present_and_bigger_than_min_loop__F34 = (music_needed__A1 > section_1__C13 + minimum_loop_length_in_seconds__C38 + section_3__C15) && music_needed__A1 < music_length__C30 ? "A" : "N"


            // s2 present but smaller than min loop ✅
            //shorter B
            section_2_present_but_smaller_than_min_loop__F38 = (music_needed__A1 < (section_1__C13 + minimum_loop_length_in_seconds__C38 + section_3__C15)) && music_needed__A1 > (section_1__C13 + section_3__C15) ? "B" : "N";

            // No s2 present, but s3 present AND s1SZ or more ✅
            //short C
            section_2_not_present_but_s1SZ_or_more__F47 = (music_needed__A1 >= (section_3__C15 + section_1_safezone_seconds__C25) && music_needed__A1 < (section_1__C13 + section_3__C15)) ? "C" : "N";


            //No s2 present, no complete s3 present, but s1SZ & s3SZ or more ✅
            //Short D
            section_2_not_present_but_s1SZ_and_s3SZ_or_more__F54 = (music_needed__A1 >= (section_3_safezone_seconds__C28 + section_1_safezone_seconds__C25) && music_needed__A1 < (section_1_safezone_seconds__C25 + section_3__C15)) ? "D" : "N";


            if (section_2_present_and_bigger_than_min_loop__F34 == "A") {
                shorter_A_skip_from__H35 = (full_loop_length_in_bars_CMS__C7 + loop_start_at_bar__C22) - (shorter_diff_in_bars__H29 + one_beat_as_decimal__C42)
                shorter_A_skip_from__H36 = full_loop_length_in_bars_CMS__C7 + loop_start_at_bar__C22;
            } else if (section_2_present_but_smaller_than_min_loop__F38 == "B") {
                shorter_B_skip_from__H44 = loop_start_at_bar__C22 + minumum_loop_length_in_bars__C37;
                shorter_B_skip_to__H45 = full_loop_length_in_bars_CMS__C7 + loop_start_at_bar__C22 + (Math.ceil((((section_1__C13 - offset_in_seconds__C6) + (minimum_loop_length_in_seconds__C38 + section_3__C15) - music_needed__A1) / bar_length__C12) * time_signature__C2) / time_signature__C2);
            } else if (section_2_not_present_but_s1SZ_or_more__F47 == "C") {
                shorter_C_skip_from__H52 = Math.floor(((music_needed__A1 - section_3__C15) / bar_length__C12) * time_signature__C2) / time_signature__C2 + one_beat_as_decimal__C42;
                shorter_C_skipt_to__H53 = full_loop_length_in_bars_CMS__C7 + loop_start_at_bar__C22;
            } else if (section_2_not_present_but_s1SZ_and_s3SZ_or_more__F54 == "D") {
                shorter_D_skip_from__H67 = section_1_safezone_bars_position__C23;
                shorter_D_skip_to__H68 = Math.ceil((section_3__C15 / bar_length__C12) * time_signature__C2) / time_signature__C2 - (Math.floor(Math.floor(music_needed__A1 / bar_length__C12) * time_signature__C2) / time_signature__C2 - section_1_safezone_bars__C24) + (loop_start_at_bar__C22 + full_loop_length_in_bars_CMS__C7) + one_beat_as_decimal__C42;
            }



        }


        let number_of_loops_required__C16 = music_needed__A1 > music_length__C30 ? rounded_loops_fitting_in_diff__F10 : 0;


        global_skip_from = (section_2_present_and_bigger_than_min_loop__F34 === "A") ? shorter_A_skip_from__H35 :
            (section_2_present_but_smaller_than_min_loop__F38 === "B") ? shorter_B_skip_from__H44 :
                (section_2_not_present_but_s1SZ_or_more__F47 === "C") ? shorter_C_skip_from__H52 :
                    (section_2_not_present_but_s1SZ_and_s3SZ_or_more__F54 === "D") ? shorter_D_skip_from__H67 :
                        (music_needed__A1 > music_length__C30) ? longer_skip_from__H21 :
                            undefined;

        global_skip_to = (section_2_present_and_bigger_than_min_loop__F34 === "A") ? shorter_A_skip_from__H36 :
            (section_2_present_but_smaller_than_min_loop__F38 === "B") ? shorter_B_skip_to__H45 :
                (section_2_not_present_but_s1SZ_or_more__F47 === "C") ? shorter_C_skipt_to__H53 :
                    (section_2_not_present_but_s1SZ_and_s3SZ_or_more__F54 === "D") ? shorter_D_skip_to__H68 :
                        (music_needed__A1 > music_length__C30) ? longer_skip_to__H22 :
                            undefined;

        if (this.algorithm_mode == 'best_match') {
            global_skip_from = Math.floor(global_skip_from);
            global_skip_to = Math.floor(global_skip_to);
        }

        global_skip_from = (decimal_number_to_bar_beats(global_skip_from, time_signature__C2));
        global_skip_to = (decimal_number_to_bar_beats(global_skip_to, time_signature__C2));

        let data = {
            music_needed__A1,
            SAMPLES__C20,
            manual_insertion_value_for_non_dividable_loop_length__C39,
            time_signature__C2,
            bpm__C3,
            audio_length__C4,
            offset_in_seconds__C6,
            full_loop_length_in_bars_CMS__C7,
            clean_start_samples__C8,
            loop_length_samples__C9,
            ending_length_samples__C10,
            seconds_per_minute,
            section_1_amend_by_x_bars__C44,
            bar_length__C12,
            section_1__C13,
            section_2__C14,
            section_3__C15,
            music_length__C30,
            loop_start_at_bar__C22,
            // skip_from__C17,
            // skip_to__C18,
            total_music_in_bars__C5,
            one_beat_as_decimal__C42,
            audio_length_check__C49,
            music_length_check__C50,
            global_skip_from,
            global_skip_to,
            loop_length_divided_by_4__C32,
            rouned_down_loop_length_divided_by_4__C33,
            interim_decimal_to_check__C34,
            interim_decimal__C35,
            minimum_loop_length_calculated__C36,
            minumum_loop_length_in_bars__C37,
            minimum_loop_length_in_seconds__C38,
            minimum_loop_length_as_decimal__C40,
            diff_music_length_by_custom_length_seconds__F8,
            how_many_loops_fit_in_diff__F9,
            rounded_loops_fitting_in_diff__F10,
            interim_rounding_diff_loop__F11,
            interim_decimal_diff_check__F12,
            partial_loop_shorter_or_longer__F6,
            diff__H8,
            longer_skip_from__H21,
            longer_skip_to__H22,
            section_1_safezone_bars__C24,
            section_1_safezone_bars_position__C23,
            section_1_safezone_seconds__C25,
            section_3_safezone_bars_position__C26,
            section_3_safezone_bars__C27,
            section_3_safezone_seconds__C28,
            shorter_diff_in_bars__H29,
            section_2_present_and_bigger_than_min_loop__F34,
            shorter_A_skip_from__H35,
            shorter_A_skip_from__H36,
            section_2_present_but_smaller_than_min_loop__F38,
            shorter_B_skip_from__H44,
            shorter_B_skip_to__H45,
            section_2_not_present_but_s1SZ_or_more__F47,
            shorter_C_skip_from__H52,
            shorter_C_skipt_to__H53,
            section_2_not_present_but_s1SZ_and_s3SZ_or_more__F54,
            shorter_D_skip_from__H67,
            shorter_D_skip_to__H68,
            number_of_loops_required__C16,
        }





        let algo_debug = document.getElementById('algorithm-debug');
        let algo_debug_static = document.getElementById('algorithm-static');

        let txt = `
        <li>Audio Files Length: ${audio_length__C4} </li>
        <li>Music Length (minus offset): ${music_length__C30?.toFixed(2) ?? '-'} </li>
        <li>Loop Length: ${full_loop_length_in_bars_CMS__C7} </li>
        <li>Clean Start: ${clean_start_samples__C8} </li>
        <li>Loop Length: ${loop_length_samples__C9} </li>
        <li>Ending Length: ${ending_length_samples__C10} </li>
        <li>Offset: ${offset_in_seconds__C6} </li>
        <li>BPM: ${bpm__C3} </li>
        <li>Time Signature: ${time_signature__C2} </li>
        <li>1 Beat As Decimal: ${one_beat_as_decimal__C42} </li>
        <li>Bar(s): ${bar_length__C12} </li>
        <li>Section 1: ${section_1__C13} </li>
        <li>Section 2: ${section_2__C14} </li>
        <li>Section 3: ${section_3__C15} </li>
        <li>Seconds Per Minute: ${seconds_per_minute} </li>
    `;

        algo_debug_static.innerHTML = txt

        // <li>Short  A <span>${(section_1__C13 + section_3__C15).toFixed(2)}-${( section_1__C13 + minimum_loop_length_in_seconds__C38 + section_3__C15 ).toFixed(2)} </span></li>
        // <li>Short  B <span>${(section_1__C13 + minimum_loop_length_in_seconds__C38 + section_3__C15).toFixed(2)} - ${ ( section_1__C13 + section_3__C15 ).toFixed(2) }</span></li>
        // <li>Short  C <span>${(section_3__C15 + section_1_safezone_seconds__C25).toFixed(2)} - ${ ( section_1__C13 + section_3__C15 ).toFixed(2) }</span></li>
        // <li>Short  D <span>${(section_3_safezone_seconds__C28 + section_1_safezone_seconds__C25).toFixed(2)}-${( section_1_safezone_seconds__C25 + section_3__C15 ).toFixed(2)} </span></li>
        let txt_2 = `
        <li>${this.link_to_cell('A1', 'Music Needed')} ${music_needed__A1} </li>
        <li>Custom Length is ${music_needed__A1 > music_length__C30 ? 'Longer' : 'Shorter'} than audio </li>
        <li>${this.link_to_cell('C32', 'Loop Length Divided By 4')} ${loop_length_divided_by_4__C32} </li>
        <li>${this.link_to_cell('C33', 'Rounded Down Loop Length Divided By 4')} ${rouned_down_loop_length_divided_by_4__C33} </li>
        <li>Interim Decimal To Check: <span>${interim_decimal_to_check__C34} </span></li>
        <li>Interim Decimal: <span>${interim_decimal__C35} </span></li>
        <li>Minimum Loop Length Calculated: <span>${minimum_loop_length_calculated__C36} </span></li>
        <li>Minimum Loop Length In Bars: <span>${minumum_loop_length_in_bars__C37} </span></li>
        <li>Minimum Loop Length In Seconds: <span>${minimum_loop_length_in_seconds__C38?.toFixed(2) ?? '-'} </span></li>
        <li>Minimum Loop Length As Decimal: <span>${minimum_loop_length_as_decimal__C40} </span></li>
        <li>Diff Music Length By Custom Length Seconds: <span>${diff_music_length_by_custom_length_seconds__F8?.toFixed(2) ?? '-'} </span></li>
        <li>${this.link_to_cell('C16', 'Number of loops required')} <span>${number_of_loops_required__C16} </span></li>
        <li>How Many Loops Fit In Diff: <span>${how_many_loops_fit_in_diff__F9?.toFixed(2) ?? '-'} </span></li>
        <li>Rounded Loops Fitting In Diff: <span>${rounded_loops_fitting_in_diff__F10} </span></li>
        <li>Interim Rounding Diff Loop: <span>${interim_rounding_diff_loop__F11} </span></li>
        <li>Interim Decimal Diff Check: <span>${interim_decimal_diff_check__F12} </span></li>
        <li>Partial Loop Shorter Or Longer: <code>${partial_loop_shorter_or_longer__F6 ?? '-'}</code> </li>
        <li>Diff: <span>${diff__H8?.toFixed(2) ?? '-'} </span></li>
        <li>Longer Skip From: <span>${longer_skip_from__H21} </span></li>
        <li>Longer Skip To: <span>${longer_skip_to__H22} </span></li>
        <li>Section 1 Safezone Bars: <span>${section_1_safezone_bars__C24} </span></li>
        <li>Section 1 Safezone Bars Position: <span>${section_1_safezone_bars_position__C23?.toFixed(2) ?? '-'} </span></li>
        <li>Section 1 Safezone Seconds: <span>${section_1_safezone_seconds__C25?.toFixed(2) ?? '-'} </span></li>
        <li>Section 3 Safezone Bars Position: <span>${section_3_safezone_bars_position__C26?.toFixed(2) ?? '-'} </span></li>
        <li>Section 3 Safezone Bars: <span>${section_3_safezone_bars__C27?.toFixed(2) ?? '-'} </span></li>
        <li>Section 3 Safezone Seconds: <span>${section_3_safezone_seconds__C28} </span></li>
        <li>If s2 present and => than min loop <code>${section_2_present_and_bigger_than_min_loop__F34} </code></li>
        <li>s2 present but smaller than min loop  <code>${section_2_present_but_smaller_than_min_loop__F38} </code></li>
        <li>No s2 present, but s3 present AND s1SZ or more <code>${section_2_not_present_but_s1SZ_or_more__F47} </code></li>
        <li>No s2 present, no complete s3 present, but s1SZ & s3SZ or more <code>${section_2_not_present_but_s1SZ_and_s3SZ_or_more__F54} </code></li>
        <li>${this.link_to_cell('H35', 'Shorter Skip A From:')} <span>${shorter_A_skip_from__H35?.toFixed(2) ?? '-'} </span></li>
        <li>${this.link_to_cell('H36', 'Shorter Skip A To:')} <span>${shorter_A_skip_from__H36?.toFixed(2) ?? '-'} </span></li>
        <li>${this.link_to_cell('H44', 'Shorter B Skip from:')} <span>${shorter_B_skip_from__H44?.toFixed(2) ?? '-'} </span></li>
        <li>${this.link_to_cell('H45', 'Shorter B Skip to:')} <span>${shorter_B_skip_to__H45?.toFixed(2) ?? '-'} </span></li>
        <li>${this.link_to_cell('H52', 'Shorter C Skip from:')} <span>${shorter_C_skip_from__H52?.toFixed(2) ?? '-'} </span></li>
        <li>${this.link_to_cell('H53', 'Shorter C Skip To:')} <span>${shorter_C_skipt_to__H53?.toFixed(2) ?? '-'} </span></li>
        <li>${this.link_to_cell('H67', 'Shorter D Skip from:')} <span>${shorter_D_skip_from__H67?.toFixed(2) ?? '-'} </span></li>
        <li>${this.link_to_cell('H68', 'Shorter D Skip To:')} <span>${shorter_D_skip_to__H68?.toFixed(2) ?? '-'} </span></li>
        <li>${this.link_to_cell('H70', 'Global Skip From:')} <span>${global_skip_from ?? '-'}  </span></li>
        <li>${this.link_to_cell('H71', 'Global Skip To:')} <span>${global_skip_to ?? '-'}  </span></li>
    `;
        algo_debug.innerHTML = txt_2;
        return data;

    }

    link_to_cell(cell, txt) {
        return `<a target="_blank" href="https://docs.google.com/spreadsheets/d/1k2TqP6hL4QRBqHbrD2kdCYpkVQK4VCF0-a-NQgA0lm8/edit#gid=1823669118&range=${cell}">${txt}</a>`
    }
}


export default (new Algorithm());